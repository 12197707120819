import { tempInterestRateBasedOnTenure, tempLoanSubmitDetails } from 'constant/appConstant';
export const CdpInitialState = {
  compoundDetails: {},
  compoundStreetInfoDetails: {},
  compoundAmenitiesDetails: {},
  compoundGalleryDetails: {},
  propertyDetails: {},
  propertyDetailsNew: {},
  galleryDetails: {},
  galleryDetailsMobile: {},
  infoDetails: {},
  headerMenuLinks: {},
  footerLinks: {},
  locale: 'ar',
  isSeeOnMapClicked: false,
  schedulePropertyTour: false,
  isMobile: null,
  seeMoreUnitDetail: false,
  unitFilterStick: 0,
  isTabletView: null,
  savedProperties: [],
  showReportPopup: false,
  getPreviousURL: '',
  enPreviousURL: '',
  arPreviousURL: '',
  userDetails: {},
  favouriteData: [],
  mortgageCalculator: {
    monthlyIncome: 0,
    emi: 0,
    existingLoan: 0,
    tenure: 30,
    interestRateBasedOnTenure: tempInterestRateBasedOnTenure,
    loanSubmitDetails: tempLoanSubmitDetails,
  },
  sendScheduleDetails: { pageType: 'CDP', unitCompoundID: '' },
  monthlyEMI: 0,
  displayTabs: [],
  isCallNowHovering: false,
  isHovering: false,
  totalSimilarPropCount: 0,
  totalPropertyCount: 0,
  useMobileState: null,
  fileIndex: {
    current: 1,
    total: 0,
  },
  galleryData: [],
  selectedTab: 'Images',
  selectedTabMobile: '',
  showPopup: false,
  nearByProperties: null,
  similarProperties: null,
  whatsappUrl: '',
  whatsappPopUp: false,
  inputValue: '',
  searchResult: [],
  backFade: false,
  wrapperOpen: false,
  propertySearchResult: true,
  zoneOpen: false,
  listView: true,
  headerReachedTop: '',
  filterPayload: {},
  isFavourite:false,
  seeMore: true,
  unitSort: null,
  showApplyBtn: false,
  files: [],
  compoundUnitList: [],
  compoundUnitListSaved: [],
  cookiesContext: null,
  compoundBreadcrumbDetails:null,
  seeMoreUnitPopUp:false,
  onChnageFilterCount : 0,
  filterData:
  {
    page: 0,
    pageSize: 4,
    propertyFor: '',
    view: "DETAIL_PAGE_MOBILE_NEW",
    countryId: 1,
    type: 'residential',
    compoundId: 0,
    property_types: "",
    propertyTypeData: [],
    bathroomsCount: [],
    bedroomsCount: [],
    furnishingTypeId: 0,
    priceRange: null,
    propertySizeRange: null,
    showAvailableOnly: true,
    includeProperties: true,
    sort: null,
  },
  swiper: null,
  showDownload: false,
  showShare: false,
  showDetails: false,
  showExpand: false,
  expandImage: false,
  hideCount: false,
}

// const
