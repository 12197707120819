import { createContext, useContext, useReducer } from 'react';
import { reducerCombined, intialStateCombined } from '../reducer';
const AppContext = createContext();

export function AppWrapper({ children, tabsOpen }) {
    const [state, dispatch] = useReducer(reducerCombined, intialStateCombined);

    const sharedState = { state, dispatch, tabsOpen };

    return (
        <AppContext.Provider value={sharedState}>
            {children}
        </AppContext.Provider>
    );
}

export function useAppContext() {
    return useContext(AppContext);
}