export const tempInterestRateBasedOnTenure = [
  {
      "tenure_interval": "5",
      "Interest_rate": "3.82"
  },
  {
      "tenure_interval": "6",
      "Interest_rate": "3.85"
  },
  {
      "tenure_interval": "7",
      "Interest_rate": "3.88"
  },
  {
      "tenure_interval": "8",
      "Interest_rate": "3.92"
  },
  {
      "tenure_interval": "9",
      "Interest_rate": "3.96"
  },
  {
      "tenure_interval": "10",
      "Interest_rate": "3.99"
  },
  {
      "tenure_interval": "11",
      "Interest_rate": "4.06"
  },
  {
      "tenure_interval": "12",
      "Interest_rate": "4.12"
  },
  {
      "tenure_interval": "13",
      "Interest_rate": "4.18"
  },
  {
      "tenure_interval": "14",
      "Interest_rate": "4.23"
  },
  {
      "tenure_interval": "15",
      "Interest_rate": "4.30"
  },
  {
      "tenure_interval": "16",
      "Interest_rate": "4.36"
  },
  {
      "tenure_interval": "17",
      "Interest_rate": "4.43"
  },
  {
      "tenure_interval": "18",
      "Interest_rate": "4.49"
  },
  {
      "tenure_interval": "19",
      "Interest_rate": "4.55"
  },
  {
      "tenure_interval": "20",
      "Interest_rate": "4.62"
  },
  {
      "tenure_interval": "21",
      "Interest_rate": "4.66"
  },
  {
      "tenure_interval": "22",
      "Interest_rate": "4.70"
  },
  {
      "tenure_interval": "23",
      "Interest_rate": "4.74"
  },
  {
      "tenure_interval": "24",
      "Interest_rate": "4.78"
  },
  {
      "tenure_interval": "25",
      "Interest_rate": "4.82"
  },
  {
      "tenure_interval": "26",
      "Interest_rate": "4.86"
  },
  {
      "tenure_interval": "27",
      "Interest_rate": "4.90"
  },
  {
      "tenure_interval": "28",
      "Interest_rate": "4.93"
  },
  {
      "tenure_interval": "29",
      "Interest_rate": "4.97"
  },
  {
      "tenure_interval": "30",
      "Interest_rate": "5.01"
  }
]


export const tempLoanSubmitDetails = {
  total: '',
  lead_interval: '',
  lead_percent: '',
  annuity: '',
  eligible_amount: '',
  affordability_status: '',
  calc_type: '',
}

export const compoundDummyResponse = {
  data: {
    id: 35,
    propIdentityTypeId: 96,
    updatedAt: '2022-07-11T12:05:58.601Z',
    refNo: 35,
    propertyRegionId: 56,
    projectInfo: {
      currencyType: 'SAR',
      salePriceMin: 12,
      salePriceMax: 40000000,
      conversionPriceMin: 12,
      conversionPriceMax: 40000000,
      conversionUnit: 'SAR',
      address: 'Jarwal, Makkah',
      title: 'Jarwal In Compound',
      description:
        '<p>This compound is having 100 premium units designed as per the latest architecture in the heart of the city. Premium properties with high standard built quality is the USP of Vivas developers. Vivas 1 offers facilities such as Gymnasium and Lift. It also has amenities like Basketball court, Lawn tennis court, Swimming pool and Car parking.<br />This compound is having 100 premium units designed as per the latest architecture in the heart of the city. Premium properties with high standard built quality is the USP of Vivas developers. Vivas 1 offers facilities such as Gymnasium and Lift. It also has amenities like Basketball court, Lawn tennis court, Swimming pool and Car parking.</p>',
      tags: ['Limited Units', 'Featured'],
      labels: [
        {
          name: 'Project',
          type: 'project',
        },
        {
          name: 'For Sale',
          type: 'sale',
        },
        {
          name: 'For Rent',
          type: 'rent',
        },
      ],
      slug: 'jarwal-35',
      district: 'Jarwal',
      city: 'Makkah',
      districtId: 2313,
      cityId: 275,
      propertyFor: 'sale',
      managedById: 54,
      completionDate: '2022-04-20',
      zone: null,
      zoneId: null,
    },
    isVerified: true,
    location: {
      lon: '39.8175',
      lat: '21.4294',
    },
    propertyFiles: {
      floorPlan: [
        {
          name: '1650613360645-02-saving-to-pdf.pdf',
          entityId: 20564,
        },
      ],
      brochure: [
        {
          name: '1650613363710-02-saving-to-pdf.pdf',
          entityId: 20564,
        },
      ],
      gallery: [
        {
          type: 'video',
          content: 'https://www.youtube.com/watch?v=NcuOFJjLqOQ',
        },
        {
          type: 'image',
          content: '1651225813099-30.jpg',
        },
        {
          type: 'image',
          content: '1651225813637-20.jpg',
        },
        {
          type: 'image',
          content: '1651225813393-10.jpg',
        },
      ],
      images: ['1651225813099-30.jpg', '1651225813637-20.jpg', '1651225813393-10.jpg'],
    },
    developerInfo: {
      name: 'SAGAR',
      logo: '1650874361283-retirement.jpg',
      about: '<p>Testing</p>',
    },
    contactDetails: {
      whatsApp: 920034778,
      phone: 920034778,
      whatsAppNumberCountryCode: '+966',
      phoneNumberCountryCode: '+966',
      regaAdvertiserNumber: '5857983',
      regaAuthorizationNumber: '1857983',
      managedBy: 'Quara Reaility',
      seller: 'DLF Group',
    },
    navigationTabs: [
      {
        slug: 'photos',
        name: 'Photos',
        id: 1,
      },
      {
        slug: 'projectInfo',
        name: 'Overview',
        id: 2,
      },
      {
        slug: 'units',
        name: 'Unit Details',
        id: 3,
      },
      {
        slug: 'brochures',
        name: 'Brochure & Plans',
        id: 4,
      },
      {
        slug: 'location',
        name: 'Locality',
        id: 5,
      },
      {
        slug: 'developerInfo',
        name: 'About Developer',
        id: 6,
      },
    ],
    additionalAttributes: [
      {
        key: 'propertyMainType',
        label: 'Property Type',
        value: 'Residential',
      },
      {
        key: 'propertyFacade',
        label: 'Facade',
        value: 'East',
      },
      {
        key: 'electricityMeter',
        label: 'Electricity',
        value: 'Yes',
      },
      {
        key: 'waterMeter',
        label: 'Water',
        value: 'Yes',
      },
    ],
    attributes: [
      {
        key: 'noOfBedrooms',
        name: 'BedRooms',
        value: [1, 2, 3, 4, 5],
        image: 'noofbedrooms.png',
        iconClass: 'bedroom',
      },
      {
        key: 'noOfBathrooms',
        name: 'BathRooms',
        value: [1, 2, 3, 4, 5],
        image: 'noofbathrooms.png',
        iconClass: 'bathroom',
      },
      {
        key: 'noOfLivingrooms',
        name: 'LivingRooms',
        value: [1, 2, 3, 4, 5],
        image: 'nooflivingrooms.png',
        iconClass: 'living-room',
      },
      {
        key: 'noOfGuestrooms',
        name: 'GuestRooms',
        value: [1, 2, 3, 4, 5],
        image: 'noofguestrooms.png',
        iconClass: 'guest-room',
      },
    ],
    propertyTypes: [
      {
        name: 'villa',
        count: 1,
      },
      {
        name: 'apartment',
        count: 0,
      },
      {
        name: 'duplex',
        count: 0,
      },
    ],
    unitsAvailable: {
      total: 44,
      available: 20,
      sale: {
        price: 20000,
        count: 10
      },
      rent: {
        price: 1200000,
        count: 12,
        cycle: 'year'
      }
    },
    views: {
      count: 126,
      days: 7,
    },
  },
}

export const galleryDetails1 = {
  "videos": {
    "thumbnail": null,
    "type": "video",
    "iconClass": "youtube",
    "slideTime": 5,
    "data": [
      {
        "content": "https://www.youtube.com/watch?v=zjOupPh6Ra0&ab_channel=YourAppleTuber%F0%9F%87%AE%F0%9F%87%B3",
        "like": 0
      }
    ]
  },
  "images": {
    "thumbnail": null,
    "type": "image",
    "iconClass": "image",
    "slideTime": 5,
    "data": [
      {
        "content": "5d8c21dc-64ad-440e-bbed-062cd63d63fc.PNG",
        "like": 0
      }
    ]
  },
  "floorPlan": [
    {
      "thumbnail": "https://assets-qa3.wasalt.com/others/icons/floor-plan-thumb.png",
      "type": "floorPlan",
      "iconClass": "floor-plan",
      "slideTime": 5,
      "data": [
        {
          "content": "1f717d09-f97d-40d8-9622-973976f2a56c.pdf",
          "like": 0,
          "type": "pdf",
          "entityId": 695
        }, {
          "content": "2247227b-8be3-4502-81b6-5f51111c8c86.pdf",
          "like": 0,
          "type": "pdf",
          "entityId": 695
        }
      ]
    }
  ],
  "brochure": [
    {
      "thumbnail": "https://assets-qa3.wasalt.com/others/icons/brochure-thumb.png",
      "type": "brochure",
      "iconClass": "pdf-file",
      "slideTime": 5,
      "data": [
        {
          "content": "9cbbfe34-f012-46d0-a3a0-6974c5053062.pdf",
          "like": 0,
          "type": "pdf",
          "entityId": 695
        }, {
          "content": "9cbbfe34-f012-46d0-a3a0-6974c5053062.pdf",
          "like": 0,
          "type": "pdf",
          "entityId": 695
        }
      ]
    }
  ]
}

export const galleryDetails2 = {
  "videos": {
    "thumbnail": null,
    "type": "video",
    "iconClass": "youtube",
    "slideTime": 5,
    "data": [
      {
        "content": "https://www.youtube.com/watch?v=zjOupPh6Ra0&ab_channel=YourAppleTuber%F0%9F%87%AE%F0%9F%87%B3",
        "like": 0
      }
    ]
  },
  "images": {
    "thumbnail": null,
    "type": "image",
    "iconClass": "image",
    "slideTime": 5,
    "data": [
      {
        "content": "5d8c21dc-64ad-440e-bbed-062cd63d63fc.PNG",
        "like": 0
      }
    ]
  },
  "floorPlan1": {
    "thumbnail": "https://assets-qa3.wasalt.com/others/icons/floor-plan-thumb.png",
    "type": "floorPlan",
    "iconClass": "floor-plan",
    "slideTime": 5,
    "data": [
      {
        "content": "1f717d09-f97d-40d8-9622-973976f2a56c.pdf",
        "like": 0,
        "type": "pdf",
        "entityId": 695
      }
    ],
    "contentType": "pdf",
    "entityId": 695
  }, "floorPlan2": {
    "thumbnail": "https://assets-qa3.wasalt.com/others/icons/floor-plan-thumb.png",
    "type": "floorPlan",
    "iconClass": "floor-plan",
    "slideTime": 5,
    "data": [
      {
        "content": "1f717d09-f97d-40d8-9622-973976f2a56c.pdf",
        "like": 0,
        "type": "pdf",
        "entityId": 695
      }
    ],
    "contentType": "pdf",
    "entityId": 695
  },
  "brochure2": {
    "thumbnail": "https://assets-qa3.wasalt.com/others/icons/brochure-thumb.png",
    "type": "brochure",
    "iconClass": "pdf-file",
    "slideTime": 5,
    "data": [
      {
        "content": "8ba32ac7-fdfa-476e-8e6b-50e2855e6b3d.pdf",
        "like": 0,
        "type": "pdf",
        "entityId": 695
      }
    ],
    "contentType": "pdf",
    "entityId": 695
  }, "brochure1": {
    "thumbnail": "https://assets-qa3.wasalt.com/others/icons/brochure-thumb.png",
    "type": "brochure",
    "iconClass": "pdf-file",
    "slideTime": 5,
    "data": [
      {
        "content": "8ba32ac7-fdfa-476e-8e6b-50e2855e6b3d.pdf",
        "like": 0,
        "type": "pdf",
        "entityId": 695
      }
    ],
    "contentType": "pdf",
    "entityId": 695
  }
}

export const compoundUnitDetails = {
  data: {
    status: true,
    data: {
      count: 9,
      properties: [
        {
          isVerified: true,
          publishedAt: '2022-06-27T16:45:02.822Z',
          fullDarReference: 'SAU-BU-814764',
          darReference: '814764',
          unitNo: 'A-1205',
          id: 21857,
          attributes: [
            {
              key: 'noOfBedrooms',
              name: 'Bedrooms',
              value: 5,
              image: 'https://assets-dev2.wasalt.com/others/icons/noofbedroomsbnw.png',
              iconClass: 'bedroom',
            },
            {
              key: 'noOfBathrooms',
              name: 'Bathrooms',
              value: 5,
              image: 'https://assets-dev2.wasalt.com/others/icons/noofbathroomsbnw.png',
              iconClass: 'bathroom',
            },
            {
              key: 'builtUpArea',
              name: 'Built-Up Area',
              value: '8000',
              image: 'https://assets-dev2.wasalt.com/others/icons/builtupareabnw.png',
              unit: 'SQM',
              iconClass: 'builtup-area',
            },
          ],
          propertyRegionId: 56,
          managedById: 55,
          propertyInfo: {
            currencyType: 'SAR',
            description: '',
            title: '5 Bedroom(s) Apartment for Sale',
            unitType: 'SQM',
            possessionType: null,
            slug: '5-bedrooms-apartment-sale-21857',
            salePrice: 800900,
            expectedRent: '',
            tags: ['Available', 'For Sale'],
            propertyFor: 'sale',
            conversionPrice: 800900,
            conversionUnit: 'SAR',
            statusTypeId: 505,
            statusTypeSlug: 'approved',
          },
        },
        {
          isVerified: true,
          publishedAt: '2022-06-27T16:45:02.822Z',
          fullDarReference: 'SAU-BU-814764',
          darReference: '814764',
          unitNo: 'A-1206',
          id: 21858,
          attributes: [
            {
              key: 'noOfBedrooms',
              name: 'Bedrooms',
              value: 5,
              image: 'https://assets-dev2.wasalt.com/others/icons/noofbedroomsbnw.png',
              iconClass: 'bedroom',
            },
            {
              key: 'noOfBathrooms',
              name: 'Bathrooms',
              value: 5,
              image: 'https://assets-dev2.wasalt.com/others/icons/noofbathroomsbnw.png',
              iconClass: 'bathroom',
            },
            {
              key: 'builtUpArea',
              name: 'Built-Up Area',
              value: '8000',
              image: 'https://assets-dev2.wasalt.com/others/icons/builtupareabnw.png',
              unit: 'SQM',
              iconClass: 'builtup-area',
            },
          ],
          propertyRegionId: 56,
          managedById: 55,
          propertyInfo: {
            currencyType: 'SAR',
            description: '',
            title: '5 Bedroom(s) Apartment for Sale',
            unitType: 'SQM',
            possessionType: null,
            slug: '5-bedrooms-apartment-sale-21857',
            salePrice: 800900,
            expectedRent: '',
            tags: ['Available', 'For Sale'],
            propertyFor: 'sale',
            conversionPrice: 800900,
            conversionUnit: 'SAR',
            statusTypeId: 505,
            statusTypeSlug: 'approved',
          },
        },
        {
          isVerified: true,
          publishedAt: '2022-06-27T16:45:02.822Z',
          fullDarReference: 'SAU-BU-814764',
          darReference: '814764',
          unitNo: 'A-1207',
          id: 21859,
          attributes: [
            {
              key: 'noOfBedrooms',
              name: 'Bedrooms',
              value: 6,
              image: 'https://assets-dev2.wasalt.com/others/icons/noofbedroomsbnw.png',
              iconClass: 'bedroom',
            },
            {
              key: 'noOfBathrooms',
              name: 'Bathrooms',
              value: 5,
              image: 'https://assets-dev2.wasalt.com/others/icons/noofbathroomsbnw.png',
              iconClass: 'bathroom',
            },
            {
              key: 'builtUpArea',
              name: 'Built-Up Area',
              value: '8000',
              image: 'https://assets-dev2.wasalt.com/others/icons/builtupareabnw.png',
              unit: 'SQM',
              iconClass: 'builtup-area',
            },
          ],
          propertyRegionId: 56,
          managedById: 55,
          propertyInfo: {
            currencyType: 'SAR',
            description: '',
            title: '6 Bedroom(s) Apartment for Sale',
            unitType: 'SQM',
            possessionType: null,
            slug: '6-bedrooms-apartment-sale-21858',
            salePrice: 800900,
            expectedRent: '',
            tags: ['Sold', 'For Sale'],
            propertyFor: 'sale',
            conversionPrice: 800900,
            conversionUnit: 'SAR',
            statusTypeId: 512,
            statusTypeSlug: 'occupied',
          },
        },
        {
          isVerified: true,
          publishedAt: '2022-06-27T16:45:02.822Z',
          fullDarReference: 'SAU-BU-814764',
          darReference: '814764',
          unitNo: 'A-1208',
          id: 21860,
          attributes: [
            {
              key: 'noOfBedrooms',
              name: 'Bedrooms',
              value: 6,
              image: 'https://assets-dev2.wasalt.com/others/icons/noofbedroomsbnw.png',
              iconClass: 'bedroom',
            },
            {
              key: 'noOfBathrooms',
              name: 'Bathrooms',
              value: 5,
              image: 'https://assets-dev2.wasalt.com/others/icons/noofbathroomsbnw.png',
              iconClass: 'bathroom',
            },
            {
              key: 'builtUpArea',
              name: 'Built-Up Area',
              value: '8000',
              image: 'https://assets-dev2.wasalt.com/others/icons/builtupareabnw.png',
              unit: 'SQM',
              iconClass: 'builtup-area',
            },
          ],
          propertyRegionId: 56,
          managedById: 55,
          propertyInfo: {
            currencyType: 'SAR',
            description: '',
            title: '6 Bedroom(s) Apartment for Sale',
            unitType: 'SQM',
            possessionType: null,
            slug: '6-bedrooms-apartment-sale-21858',
            salePrice: 800900,
            expectedRent: '',
            tags: ['Sold', 'For Sale'],
            propertyFor: 'sale',
            conversionPrice: 800900,
            conversionUnit: 'SAR',
            statusTypeId: 512,
            statusTypeSlug: 'occupied',
          },
        },
        {
          isVerified: true,
          publishedAt: '2022-06-27T16:45:02.822Z',
          fullDarReference: 'SAU-BU-814764',
          darReference: '814764',
          unitNo: 'A-1209',
          id: 21861,
          attributes: [
            {
              key: 'noOfBedrooms',
              name: 'Bedrooms',
              value: 6,
              image: 'https://assets-dev2.wasalt.com/others/icons/noofbedroomsbnw.png',
              iconClass: 'bedroom',
            },
            {
              key: 'noOfBathrooms',
              name: 'Bathrooms',
              value: 5,
              image: 'https://assets-dev2.wasalt.com/others/icons/noofbathroomsbnw.png',
              iconClass: 'bathroom',
            },
            {
              key: 'builtUpArea',
              name: 'Built-Up Area',
              value: '8000',
              image: 'https://assets-dev2.wasalt.com/others/icons/builtupareabnw.png',
              unit: 'SQM',
              iconClass: 'builtup-area',
            },
          ],
          propertyRegionId: 56,
          managedById: 55,
          propertyInfo: {
            currencyType: 'SAR',
            description: '',
            title: '6 Bedroom(s) Apartment for Sale',
            unitType: 'SQM',
            possessionType: null,
            slug: '6-bedrooms-apartment-sale-21858',
            salePrice: 800900,
            expectedRent: '',
            tags: ['Sold', 'For Sale'],
            propertyFor: 'sale',
            conversionPrice: 800900,
            conversionUnit: 'SAR',
            statusTypeId: 512,
            statusTypeSlug: 'occupied',
          },
        },
      ],
    },
    "message": "Successful operation"
  }
}
export const compoundDummyStreetInfoResponse = {
  status: true,
  data: {
    streetInfo: [
      {
        id: 1495,
        facingTypeId: 8,
        streetWidth: 11,
        unit: 'm',
        facing: 'East',
        iconClass: 'street-east',
      },
      {
        id: 1496,
        facingTypeId: 9,
        streetWidth: 22,
        unit: 'm',
        facing: 'West',
        iconClass: 'street-west',
      },
      {
        id: 1497,
        facingTypeId: 10,
        streetWidth: 33,
        unit: 'm',
        facing: 'North',
        iconClass: 'street-north',
      },
      {
        id: 1498,
        facingTypeId: 11,
        streetWidth: 44,
        unit: 'm',
        facing: 'South',
        iconClass: 'street-south',
      },
    ],
  },
  message: 'Successful operation',
}

export const compoundDummyAmenitiesResponse = {
  status: true,
  data: {
    amenities: [
      {
        nameAr: 'غرفة سائق',
        ordering: 13,
        icon: 'central-ac.png',
        id: 42,
        nameEn: 'Central AC',
        amenityTypeId: 31,
        slug: 'central-ac',
        iconClass: 'central-ac',
        name: 'Central AC',
      },
      {
        nameAr: 'غرفة سائق',
        ordering: 13,
        icon: 'central-ac.png',
        id: 42,
        nameEn: 'Central AC',
        amenityTypeId: 31,
        slug: 'central-ac',
        iconClass: 'central-ac',
        name: 'Central AC',
      },

      {
        nameAr: 'غرفة سائق',
        ordering: 13,
        icon: 'central-ac.png',
        id: 42,
        nameEn: 'Central AC',
        amenityTypeId: 31,
        slug: 'central-ac',
        iconClass: 'central-ac',
        name: 'Central AC',
      },
      {
        nameAr: 'غرفة سائق',
        ordering: 13,
        icon: 'central-ac.png',
        id: 42,
        nameEn: 'Central AC',
        amenityTypeId: 31,
        slug: 'central-ac',
        iconClass: 'central-ac',
        name: 'Central AC',
      },
      {
        nameAr: 'غرفة سائق',
        ordering: 13,
        icon: 'central-ac.png',
        id: 42,
        nameEn: 'Central AC',
        amenityTypeId: 31,
        slug: 'central-ac',
        iconClass: 'central-ac',
        name: 'Central AC',
      },
      {
        nameAr: 'غرفة سائق',
        ordering: 13,
        icon: 'central-ac.png',
        id: 42,
        nameEn: 'Central AC',
        amenityTypeId: 31,
        slug: 'central-ac',
        iconClass: 'central-ac',
        name: 'Central AC',
      },
      {
        nameAr: 'غرفة سائق',
        ordering: 13,
        icon: 'central-ac.png',
        id: 42,
        nameEn: 'Central AC',
        amenityTypeId: 31,
        slug: 'central-ac',
        iconClass: 'central-ac',
        name: 'Central AC',
      },
      {
        nameAr: 'غرفة سائق',
        ordering: 13,
        icon: 'central-ac.png',
        id: 42,
        nameEn: 'Central AC',
        amenityTypeId: 31,
        slug: 'central-ac',
        iconClass: 'central-ac',
        name: 'Central AC',
      },
      {
        nameAr: 'غرفة سائق',
        ordering: 13,
        icon: 'central-ac.png',
        id: 42,
        nameEn: 'Central AC',
        amenityTypeId: 31,
        slug: 'central-ac',
        iconClass: 'central-ac',
        name: 'Central AC',
      },
      {
        nameAr: 'غرفة سائق',
        ordering: 13,
        icon: 'central-ac.png',
        id: 42,
        nameEn: 'Central AC',
        amenityTypeId: 31,
        slug: 'central-ac',
        iconClass: 'central-ac',
        name: 'Central AC',
      },

      {
        nameAr: 'غرفة سائق',
        ordering: 13,
        icon: 'central-ac.png',
        id: 42,
        nameEn: 'Central AC',
        amenityTypeId: 31,
        slug: 'central-ac',
        iconClass: 'central-ac',
        name: 'Central AC',
      },
      {
        nameAr: 'غرفة سائق',
        ordering: 13,
        icon: 'central-ac.png',
        id: 42,
        nameEn: 'Central AC',
        amenityTypeId: 31,
        slug: 'central-ac',
        iconClass: 'central-ac',
        name: 'Central AC',
      },
      {
        nameAr: 'غرفة سائق',
        ordering: 13,
        icon: 'central-ac.png',
        id: 42,
        nameEn: 'Central AC',
        amenityTypeId: 31,
        slug: 'central-ac',
        iconClass: 'central-ac',
        name: 'Central AC',
      },
      {
        nameAr: 'غرفة سائق',
        ordering: 13,
        icon: 'central-ac.png',
        id: 42,
        nameEn: 'Central AC',
        amenityTypeId: 31,
        slug: 'central-ac',
        iconClass: 'central-ac',
        name: 'Central AC',
      },
      {
        nameAr: 'غرفة سائق',
        ordering: 13,
        icon: 'central-ac.png',
        id: 42,
        nameEn: 'Central AC',
        amenityTypeId: 31,
        slug: 'central-ac',
        iconClass: 'central-ac',
        name: 'Central AC',
      },
      {
        nameAr: 'غرفة سائق',
        ordering: 13,
        icon: 'central-ac.png',
        id: 42,
        nameEn: 'Central AC',
        amenityTypeId: 31,
        slug: 'central-ac',
        iconClass: 'central-ac',
        name: 'Central AC',
      },
      {
        nameAr: 'غرفة سائق',
        ordering: 13,
        icon: 'central-ac.png',
        id: 42,
        nameEn: 'Central AC',
        amenityTypeId: 31,
        slug: 'central-ac',
        iconClass: 'central-ac',
        name: 'Central AC',
      },
      {
        nameAr: 'غرفة سائق',
        ordering: 13,
        icon: 'central-ac.png',
        id: 42,
        nameEn: 'Central AC',
        amenityTypeId: 31,
        slug: 'central-ac',
        iconClass: 'central-ac',
        name: 'Central AC',
      },
      {
        nameAr: 'غرفة سائق',
        ordering: 13,
        icon: 'central-ac.png',
        id: 42,
        nameEn: 'Central AC',
        amenityTypeId: 31,
        slug: 'central-ac',
        iconClass: 'central-ac',
        name: 'Central AC',
      },
      {
        nameAr: 'غرفة سائق',
        ordering: 13,
        icon: 'central-ac.png',
        id: 42,
        nameEn: 'Central AC',
        amenityTypeId: 31,
        slug: 'central-ac',
        iconClass: 'central-ac',
        name: 'Central AC',
      },
      {
        nameAr: 'غرفة سائق',
        ordering: 13,
        icon: 'central-ac.png',
        id: 42,
        nameEn: 'Central AC',
        amenityTypeId: 31,
        slug: 'central-ac',
        iconClass: 'central-ac',
        name: 'Central AC',
      },
      {
        nameAr: 'غرفة سائق',
        ordering: 13,
        icon: 'central-ac.png',
        id: 42,
        nameEn: 'Central AC',
        amenityTypeId: 31,
        slug: 'central-ac',
        iconClass: 'central-ac',
        name: 'Central AC',
      },
    ],
    streetInfo: [
      {
        id: 1495,
        facingTypeId: 8,
        streetWidth: 11,
        unit: 'm',
        facing: 'East',
        iconClass: 'street-east',
      },
      {
        id: 1496,
        facingTypeId: 9,
        streetWidth: 22,
        unit: 'm',
        facing: 'West',
        iconClass: 'street-west',
      },
      {
        id: 1497,
        facingTypeId: 10,
        streetWidth: 33,
        unit: 'm',
        facing: 'North',
        iconClass: 'street-north',
      },
      {
        id: 1498,
        facingTypeId: 11,
        streetWidth: 44,
        unit: 'm',
        facing: 'South',
        iconClass: 'street-south',
      },
    ],
  },
  message: 'Successful operation',
}
export const compoundDummyGalleryResponse = {
  status: true,
  data: {
    videos: {
      thumbnail: null,
      type: 'video',
      iconClass: 'youtube',
      slideTime: 5,
      data: [
        {
          content: 'https://www.youtube.com/watch?v=NcuOFJjLqOQ',
          like: 0,
        },
      ],
    },
    images: {
      thumbnail: null,
      type: 'image',
      iconClass: 'image',
      slideTime: 5,
      data: [
        { content: 'dsc0107.jpg', like: 0 },

        { content: 'dsc0088.jpg', like: 0 },

        { content: 'dsc0086.jpg', like: 0 },

        { content: 'rafaldawn.jpg', like: 0 },

        { content: 'ph-05-030.jpg', like: 0 },

        { content: 'ph-05-003.jpg', like: 0 },
        { content: '1.jpg', like: 0 },

      ],
    },
    brochure: {
      thumbnail: 'https://assets-dev.daralarkanonline.com/others/icons/brochure-thumb.png',
      type: 'brochure',
      iconClass: 'pdf-file',
      slideTime: 5,
      data: [
        {
          content: '1656051036249-gre_research_validity_data.pdf',
        },
      ],
    },
    // brochure2: {
    //   thumbnail: 'https://assets-dev.daralarkanonline.com/others/icons/brochure-thumb.png',
    //   type: 'brochure',
    //   iconClass: 'pdf-file',
    //   slideTime: 5,
    //   data: [
    //     {
    //       content: '1656051036249-gre_research_validity_data.pdf',
    //     },
    //   ],
    // },
    floorPlans: {
      thumbnail: 'https://assets-dev.daralarkanonline.com/others/icons/floor-plan-thomb.png',
      type: 'floorPlan',
      iconClass: 'floor-plan',
      slideTime: 5,
      data: [
        {
          content: '1656051024333-landscape.pdf',
        },
      ],
    },
    floorPlan2: {
      thumbnail: 'https://assets-dev.daralarkanonline.com/others/icons/floor-plan-thomb.png',
      type: 'floorPlan',
      iconClass: 'floor-plan',
      slideTime: 5,
      data: [
        {
          content: '1656051024333-landscape.pdf',
        },
      ],
    },
  },
  message: 'Successful operation',
}

export const unitDummyResponse = {
  data: {
    propertyOwner: {
      whatsApp: '920034774',
      companyLogo: 'users/1038/profile/null',
      userAvatar: null,
      phoneNumberCountryCode: '+966',
      regaAuthorizationNumber: null,
      enUserRole: 'Owner',
      phone: '920034774',
      arUserRole: 'صاحب',
      postedAs: 47,
      name: 'Faris Al-Otaebi',
      whatsAppNumberCountryCode: '+966',
      regaAdvertiserNumber: '5857983',
      email: null,
    },
    isVerified: false,
    external360Link: null,
    isUpdated: true,
    externalVideoLink: null,
    id: 548,
    updatedAt: '2021-08-17T11:14:01.326Z',
    publishedAt: '2021-08-17T11:14:01.326Z',
    verificationStatus: {
      wathiq: {
        isCondtrained: null,
        isHalt: null,
        isPawned: null,
        isTestament: null,
      },
    },
    fullDarReference: 'SARUH-SA-BU-VL-752569',
    propIdentityTypeId: 95,
    darReference: '752569',
    location: {
      lon: '46.72728',
      lat: '24.50248',
    },
    attributes: [
      {
        key: 'noOfBedrooms',
        name: 'BEDROOMS',
        value: 3,
        image: 'noofbedrooms.png',
        iconClass: 'bedroom',
      },
      {
        key: 'noOfBathrooms',
        name: 'BATHROOMS',
        value: 4,
        image: 'noofbathrooms.png',
        iconClass: 'bathroom',
      },
      {
        key: 'builtUpArea',
        name: 'BUILT-UP AREA',
        value: '378',
        image: 'builtuparea.png',
        unit: 'SQM',
        iconClass: 'builtup-area',
      },
      {
        key: 'carpetArea',
        name: 'LAND AREA',
        value: '483',
        image: 'carpetarea.png',
        unit: 'SQM',
        iconClass: 'land-area',
      },
      {
        key: 'furnishingType',
        name: 'FURNITURE',
        value: null,
        image: 'furnishingtype.png',
        iconClass: 'furniture',
      },
    ],
    attributes2: {
      streetWidth: null,
      noOfFloors: null,
      floorNumber: null,
      leaseAmount: null,
      leaseContractEndDate: null,
      noOfParkings: '',
      furnishingType: null,
      facingType: null,
    },
    additionalAttributes: [
      {
        key: 'propertyMainType',
        label: 'Property Type',
        value: 'Residential',
      },
    ],
    iconBaseURL: 'https://assets-dev.daralarkanonline.com',
    managedById: 54,
    propertyRegionId: 56,
    propertyInfo: {
      currencyType: 'SAR',
      country: 'Saudi Arabia',
      propertyMainType: 'Residential',
      address: 'Badr, South Riyadh, Riyadh',
      city: 'Riyadh',
      propertySubType: 'Villa',
      furnishingType: null,
      facingType: null,
      description:
        '<p>&lt;p&gt;A modern single-story villa consisted of 2 spacious bedrooms, one master bedroom, four bathrooms, a magnificent annex with a fireplace in a bright yard, elegantly-designed living rooms and a car entrance. The roof is also established to build 3 apartments.&lt;/p&gt;</p>\n',
      title: 'Villa 3 Bedrooms in Bader, Riyadh, Saudi Arabia',
      unitType: 'SQM',
      possessionType: null,
      zone: 'Badr',
      propertyName: 'Villa for Sale',
      slug: 'downtown-96',
      territory: 'South Riyadh',
      salePrice: 875000,
      expectedRent: '',
      tags: [
        {
          name: 'For Sale',
        },
      ],
      propertyFor: 'sale',
      conversionPrice: 875000,
      conversionUnit: 'SAR',
      contactDetails: {
        FROM_EMAIL: 'noreply@daralarkanonline.com',
        DAAO_TEAM_WHATSAPP: '+966 53 388 5500',
        ADMIN_EMAIL: 'admin@daralarkanonline.com',
        DAAO_TEAM_EMAIL: 'test2daao@yopmail.com',
        DAAO_TEAM_PHONE: '8001233227',
      },
      projectId: null,
      districId: 2138,
      cityId: 273,
      mainTypeId: 2,
      propertySubTypeSlug: 'villa-townhouse',
      alternateSlug: 'فيلا-3-غرف-نوم-في-بدر،-الرياض،-المملكة-العربية-السعودية-548',
      averageSalePricePerSqm: 1812,
      averageSalePriceLowerPercentage: 96.1,
    },
    ageOfListing: 'Updated 313 days ago',
    views: {
      count: 200,
      days: 7,
    },
  },
}
export const unitListDummyResponse = {
  data: {
    count: 9,
    properties: [
      {
        isVerified: true,
        publishedAt: '2022-06-27T16:45:02.822Z',
        fullDarReference: 'SAU-BU-814764',
        darReference: '814764',
        unitNo: 'A-1205',
        id: 25768,
        attributes: [
          {
            key: 'noOfBedrooms',
            name: 'Bedrooms',
            value: 5,
            image: 'https://assets-dev2.wasalt.com/others/icons/noofbedroomsbnw.png',
            iconClass: 'bedroom',
          },
          {
            key: 'noOfBathrooms',
            name: 'Bathrooms',
            value: 5,
            image: 'https://assets-dev2.wasalt.com/others/icons/noofbathroomsbnw.png',
            iconClass: 'bathroom',
          },
          {
            key: 'builtUpArea',
            name: 'Built-Up Area',
            value: '8000',
            image: 'https://assets-dev2.wasalt.com/others/icons/builtupareabnw.png',
            unit: 'SQM',
            iconClass: 'builtup-area',
          },
        ],
        propertyRegionId: 56,
        managedById: 55,
        propertyInfo: {
          currencyType: 'SAR',
          description: '',
          title: '5 Bedroom(s) Apartment for Sale',
          unitType: 'SQM',
          possessionType: null,
          slug: '5-bedrooms-apartment-sale-21857',
          salePrice: 800900,
          expectedRent: '',
          tags: ['Available', 'For Sale'],
          labels: [
            {
              name: 'Available',
              type: 'available',
            },
            {
              name: 'For Sale',
              type: 'sale',
            },
          ],
          propertyFor: 'sale',
          conversionPrice: 800900,
          conversionUnit: 'SAR',
          statusTypeId: 505,
          statusTypeSlug: 'approved',
        },
      },
      {
        isVerified: true,
        publishedAt: '2022-06-27T16:45:02.822Z',
        fullDarReference: 'SAU-BU-814764',
        darReference: '814764',
        unitNo: 'A-1205',
        id: 25768,
        attributes: [
          {
            key: 'noOfBedrooms',
            name: 'Bedrooms',
            value: 5,
            image: 'https://assets-dev2.wasalt.com/others/icons/noofbedroomsbnw.png',
            iconClass: 'bedroom',
          },
          {
            key: 'noOfBathrooms',
            name: 'Bathrooms',
            value: 5,
            image: 'https://assets-dev2.wasalt.com/others/icons/noofbathroomsbnw.png',
            iconClass: 'bathroom',
          },
          {
            key: 'builtUpArea',
            name: 'Built-Up Area',
            value: '8000',
            image: 'https://assets-dev2.wasalt.com/others/icons/builtupareabnw.png',
            unit: 'SQM',
            iconClass: 'builtup-area',
          },
        ],
        propertyRegionId: 56,
        managedById: 55,
        propertyInfo: {
          currencyType: 'SAR',
          description: '',
          title: '5 Bedroom(s) Apartment for Sale',
          unitType: 'SQM',
          possessionType: null,
          slug: '5-bedrooms-apartment-sale-21857',
          salePrice: 800900,
          expectedRent: '',
          tags: ['Available', 'For Sale'],
          labels: [
            {
              name: 'Available',
              type: 'available',
            },
            {
              name: 'For Sale',
              type: 'sale',
            },
          ],
          propertyFor: 'sale',
          conversionPrice: 800900,
          conversionUnit: 'SAR',
          statusTypeId: 505,
          statusTypeSlug: 'approved',
        },
      },
      {
        isVerified: true,
        publishedAt: '2022-06-27T16:45:02.822Z',
        fullDarReference: 'SAU-BU-814764',
        darReference: '814764',
        unitNo: 'A-1205',
        id: 25768,
        attributes: [
          {
            key: 'noOfBedrooms',
            name: 'Bedrooms',
            value: 5,
            image: 'https://assets-dev2.wasalt.com/others/icons/noofbedroomsbnw.png',
            iconClass: 'bedroom',
          },
          {
            key: 'noOfBathrooms',
            name: 'Bathrooms',
            value: 5,
            image: 'https://assets-dev2.wasalt.com/others/icons/noofbathroomsbnw.png',
            iconClass: 'bathroom',
          },
          {
            key: 'builtUpArea',
            name: 'Built-Up Area',
            value: '8000',
            image: 'https://assets-dev2.wasalt.com/others/icons/builtupareabnw.png',
            unit: 'SQM',
            iconClass: 'builtup-area',
          },
        ],
        propertyRegionId: 56,
        managedById: 55,
        propertyInfo: {
          currencyType: 'SAR',
          description: '',
          title: '5 Bedroom(s) Apartment for Sale',
          unitType: 'SQM',
          possessionType: null,
          slug: '5-bedrooms-apartment-sale-21857',
          salePrice: 800900,
          expectedRent: '',
          tags: ['Available', 'For Sale'],
          labels: [
            {
              name: 'Available',
              type: 'available',
            },
            {
              name: 'For Sale',
              type: 'sale',
            },
          ],
          propertyFor: 'sale',
          conversionPrice: 800900,
          conversionUnit: 'SAR',
          statusTypeId: 505,
          statusTypeSlug: 'approved',
        },
      },
      {
        isVerified: true,
        publishedAt: '2022-06-27T16:45:02.822Z',
        fullDarReference: 'SAU-BU-814764',
        darReference: '814764',
        unitNo: 'A-1205',
        id: 25768,
        attributes: [
          {
            key: 'noOfBedrooms',
            name: 'Bedrooms',
            value: 5,
            image: 'https://assets-dev2.wasalt.com/others/icons/noofbedroomsbnw.png',
            iconClass: 'bedroom',
          },
          {
            key: 'noOfBathrooms',
            name: 'Bathrooms',
            value: 5,
            image: 'https://assets-dev2.wasalt.com/others/icons/noofbathroomsbnw.png',
            iconClass: 'bathroom',
          },
          {
            key: 'builtUpArea',
            name: 'Built-Up Area',
            value: '8000',
            image: 'https://assets-dev2.wasalt.com/others/icons/builtupareabnw.png',
            unit: 'SQM',
            iconClass: 'builtup-area',
          },
        ],
        propertyRegionId: 56,
        managedById: 55,
        propertyInfo: {
          currencyType: 'SAR',
          description: '',
          title: '5 Bedroom(s) Apartment for Sale',
          unitType: 'SQM',
          possessionType: null,
          slug: '5-bedrooms-apartment-sale-21857',
          salePrice: 800900,
          expectedRent: '',
          tags: ['Available', 'For Sale'],
          labels: [
            {
              name: 'Available',
              type: 'available',
            },
            {
              name: 'For Sale',
              type: 'sale',
            },
          ],
          propertyFor: 'sale',
          conversionPrice: 800900,
          conversionUnit: 'SAR',
          statusTypeId: 505,
          statusTypeSlug: 'approved',
        },
      },
      {
        isVerified: true,
        publishedAt: '2022-06-27T16:45:02.822Z',
        fullDarReference: 'SAU-BU-814764',
        darReference: '814764',
        unitNo: 'A-1205',
        id: 25768,
        attributes: [
          {
            key: 'noOfBedrooms',
            name: 'Bedrooms',
            value: 5,
            image: 'https://assets-dev2.wasalt.com/others/icons/noofbedroomsbnw.png',
            iconClass: 'bedroom',
          },
          {
            key: 'noOfBathrooms',
            name: 'Bathrooms',
            value: 5,
            image: 'https://assets-dev2.wasalt.com/others/icons/noofbathroomsbnw.png',
            iconClass: 'bathroom',
          },
          {
            key: 'builtUpArea',
            name: 'Built-Up Area',
            value: '8000',
            image: 'https://assets-dev2.wasalt.com/others/icons/builtupareabnw.png',
            unit: 'SQM',
            iconClass: 'builtup-area',
          },
        ],
        propertyRegionId: 56,
        managedById: 55,
        propertyInfo: {
          currencyType: 'SAR',
          description: '',
          title: '5 Bedroom(s) Apartment for Sale',
          unitType: 'SQM',
          possessionType: null,
          slug: '5-bedrooms-apartment-sale-21857',
          salePrice: 800900,
          expectedRent: '',
          tags: ['Available', 'For Sale'],
          labels: [
            {
              name: 'Available',
              type: 'available',
            },
            {
              name: 'For Sale',
              type: 'sale',
            },
          ],
          propertyFor: 'sale',
          conversionPrice: 800900,
          conversionUnit: 'SAR',
          statusTypeId: 505,
          statusTypeSlug: 'approved',
        },
      },
      {
        isVerified: true,
        publishedAt: '2022-06-27T16:45:02.822Z',
        fullDarReference: 'SAU-BU-814764',
        darReference: '814764',
        unitNo: 'A-1205',
        id: 25768,
        attributes: [
          {
            key: 'noOfBedrooms',
            name: 'Bedrooms',
            value: 5,
            image: 'https://assets-dev2.wasalt.com/others/icons/noofbedroomsbnw.png',
            iconClass: 'bedroom',
          },
          {
            key: 'noOfBathrooms',
            name: 'Bathrooms',
            value: 5,
            image: 'https://assets-dev2.wasalt.com/others/icons/noofbathroomsbnw.png',
            iconClass: 'bathroom',
          },
          {
            key: 'builtUpArea',
            name: 'Built-Up Area',
            value: '8000',
            image: 'https://assets-dev2.wasalt.com/others/icons/builtupareabnw.png',
            unit: 'SQM',
            iconClass: 'builtup-area',
          },
        ],
        propertyRegionId: 56,
        managedById: 55,
        propertyInfo: {
          currencyType: 'SAR',
          description: '',
          title: '5 Bedroom(s) Apartment for Sale',
          unitType: 'SQM',
          possessionType: null,
          slug: '5-bedrooms-apartment-sale-21857',
          salePrice: 800900,
          expectedRent: '',
          tags: ['Available', 'For Sale'],
          labels: [
            {
              name: 'Available',
              type: 'available',
            },
            {
              name: 'For Sale',
              type: 'sale',
            },
          ],
          propertyFor: 'sale',
          conversionPrice: 800900,
          conversionUnit: 'SAR',
          statusTypeId: 505,
          statusTypeSlug: 'approved',
        },
      },
      {
        isVerified: true,
        publishedAt: '2022-06-27T16:45:02.822Z',
        fullDarReference: 'SAU-BU-814764',
        darReference: '814764',
        unitNo: 'A-1205',
        id: 25768,
        attributes: [
          {
            key: 'noOfBedrooms',
            name: 'Bedrooms',
            value: 5,
            image: 'https://assets-dev2.wasalt.com/others/icons/noofbedroomsbnw.png',
            iconClass: 'bedroom',
          },
          {
            key: 'noOfBathrooms',
            name: 'Bathrooms',
            value: 5,
            image: 'https://assets-dev2.wasalt.com/others/icons/noofbathroomsbnw.png',
            iconClass: 'bathroom',
          },
          {
            key: 'builtUpArea',
            name: 'Built-Up Area',
            value: '8000',
            image: 'https://assets-dev2.wasalt.com/others/icons/builtupareabnw.png',
            unit: 'SQM',
            iconClass: 'builtup-area',
          },
        ],
        propertyRegionId: 56,
        managedById: 55,
        propertyInfo: {
          currencyType: 'SAR',
          description: '',
          title: '5 Bedroom(s) Apartment for Sale',
          unitType: 'SQM',
          possessionType: null,
          slug: '5-bedrooms-apartment-sale-21857',
          salePrice: 800900,
          expectedRent: '',
          tags: ['Available', 'For Sale'],
          labels: [
            {
              name: 'Available',
              type: 'available',
            },
            {
              name: 'For Sale',
              type: 'sale',
            },
          ],
          propertyFor: 'sale',
          conversionPrice: 800900,
          conversionUnit: 'SAR',
          statusTypeId: 505,
          statusTypeSlug: 'approved',
        },
      },
    ],
  },
}


