export const MatchmakerInitalState = {
  isShowUpgradeVisible: false,
  featureRequestPrice: '',
  pageType: '',
  source: '',
  userEligible: null,
  activeCard: false,
  bottomSheetBackDrop: null,
  brokerCount: null,
  packageId: null,
  getRequirementData:{},
  isLoading:true,
  draftData:{},
  brokerData: null,
  salePropertyData: null,
  rentPropertyData: null,
  showToastMsg: false,
  requirementData: null,
  loggedinStatus:null,
  requirement_id:null,
  fullName: '', 
  preApproved: false,
  bankDetails: {},
  draft_ID: null,
  city_ID: null,
  employmentMode: '',
  monthlySalary: '',
  age:''
}
