export const matchmakerReducer = (state, action) => {
    switch (action.type) {
      case 'SET_SHOW_UPGRADE':
        return {
          ...state,
          isShowUpgradeVisible: action.payload
        }
      case 'SET_TOTAL_AMOUNT_FOR_FEATURE_REQUEST':
        return {
          ...state,
          featureRequestPrice: action.payload
        }
      case 'SET_PAGE_TYPE':
        return {
          ...state,
          pageType: action.payload
        }     
      case 'SET_SOURCE':
        return {
          ...state,
          source: action.payload
        } 
      case 'SET_USER_ELIGIBLE':
        return {
          ...state,
          userEligible: action.payload
        }
      case 'SET_ACTIVE_CARD':
        return {
          ...state,
          activeCard: action.payload
        }
      case 'SET_BOTTOM_SHEET_BACKDROP':
        return {
          ...state,
          bottomSheetBackDrop: action.payload
        }
      case 'SET_BROKER_COUNT':
        return {
          ...state,
          brokerCount: action.payload
        }
      case 'SET_PACKAGE_COUNT':
        return {
          ...state,
          packageId: action.payload
        }
      case 'SET_GET_REQUIREMENTS_DATA':
          return {
            ...state,
            getRequirementData: action.payload
      }
      case 'SET_IS_LOADING':
          return {
            ...state,
            isLoading: action.payload
      }
      case 'SET_DRAFT_DATA':
          return {
            ...state,
            draftData: action.payload
      } 
      case 'SET_BROKER_DATA':
        return {
          ...state,
          brokerData: action.payload
        }
      case 'SET_RENT_PROPERTY_DATA':
        return {
          ...state,
          rentPropertyData: action.payload
        }
      case 'SET_SALE_PROPERTY_DATA':
        return {
          ...state,
          salePropertyData: action.payload
        }  
      case 'SET_SHOW_TOAST_MSG':
        return {
          ...state,
          showToastMsg: action.payload
        } 
      case 'SET_REQUIRMENT_DATA':
          return {
            ...state,
            requirementData: action.payload
      } 
      case 'SET_LOGGEDIN_STATUS':
          return {
            ...state,
            loggedinStatus: action.payload
          }
      case 'SET_REQUIREMENT_ID':
          return {
            ...state,
            requirement_id: action.payload
          }
  
      case 'SET_FULL_NAME':
          return {
            ...state,
            fullName: action.payload
      }  
      case 'SET_PRE_APPROVED':
          return {
            ...state,
            preApproved: action.payload
      } 
      case 'SET_BANK_DETAILS':
          return {
            ...state,
            bankDetails: action.payload
      }  
      case 'DRAFT_ID':
          return {
            ...state,
            draft_ID: action.payload
      } 
      case 'SET_CITY_ID':
          return {
            ...state,
            city_ID: action.payload
      } 
      case 'SET_EMPLOY_MODE':
        return {
          ...state,
          employmentMode: action.payload
        }
      case 'SET_MONTHLY_SALARY':
        return {
          ...state,
          monthlySalary: action.payload
        } 
      case 'SET_AGE':
        return{
          ...state,
          age: action.payload
        }
      default:
        return state
    }
  }