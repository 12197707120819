import axios from 'axios'
import {pushDataLayerEventHandler} from '@/utils/utils'
import router from 'next/router'
import { getParamsFromQuery } from 'services/queryString';

/**
 * get List of Properties | Search API
 * @param {Object} query
 * @returns
 */
export const getSearhData = async (reqHeaders, reqBody) => {
  try {
    const response = await fetch(`${process.env.NEXT_PUBLIC_API_URL_V6}/properties/filter`,{
      method: "POST",
      headers: reqHeaders,
      body: JSON.stringify(reqBody)
    })
    const res = await response.json()
    const data = res
    if (res.status === true) {
      return data
    } 
    throw res.status
  } catch (error) {
    return error?.response
  }
}

export const getUserRecentSearchByUserId = async (reqHeaders) => {
  try {
    const response = await fetch(`${process.env.NEXT_PUBLIC_API_URL_V3}/user-search`, {
      method: 'GET',
      headers: reqHeaders
    })
    const res = await response.json()
    const data = res.data
    if (res.status === true) {
      return data
    } 
    throw res.status
  } catch (error) {
    return error?.response
  }
}
export const getUserRecentSearchForLocal = async (reqHeaders,reqBody) => {
  try {
    const response = await fetch(`${process.env.NEXT_PUBLIC_API_URL_V3}/user-search`, {
      method: 'POST',
      headers: reqHeaders,
      body: JSON.stringify(reqBody)
    })
    const res = await response.json()
    const data = res.data
    if (res.status === true) {
      return data
    } 
    throw res.status
  } catch (error) {
    return error?.response
  }
}
export const getFeatureAgentData = async (reqHeaders, reqBody) => {
  try {
    if (reqBody?.zoneId || reqBody?.districtId) {
      const response = await axios({
        method: 'post',
        url: `${process.env.NEXT_PUBLIC_API_URL_V1}/agent/feature`,
        headers: reqHeaders,
        data: reqBody,
      })
      const data = response.data.data
      if (response.status) {
        return data
      } 
      throw response.status
    }
    else { 
      return [] 
    }
  } catch (error) {
    return error?.response
  }
}
export const getClientSideFeatureAgent = async (headers, body) => {
  try {
    if(body.districtId && typeof body.districtId === 'string'){
      body.districtId=body.districtId?.split(',')
    }
    const resp = await fetch(`/api/properties/featureAgent`, {
      method: 'POST',
      headers,
      body: JSON.stringify({ ...body }),
    })
    const response_json = await resp.json()
    const responseContent = await response_json.data
    const featureData = await responseContent?.data
    return featureData || [];
  } catch (error) {
    return error?.response
  }
}

export const updateUserRecentSearchByUserId = async (reqHeaders, reqBody) => {
  try {
    const response = await fetch(`${process.env.NEXT_PUBLIC_API_URL_V3}/user-search/create`, {
      method: 'POST',
      headers: reqHeaders,
      body: JSON.stringify(reqBody)
    })
    const res = await response.json()
    const data = res.data
    if (res.status === true) {
      return data
    } 
    throw res.status
  } catch (error) {
    return error?.response
  }
}
export const checkForCurrentLocation = async (reqHeaders, reqBody) => {
  try {
    const response = await fetch(`${process.env.NEXT_PUBLIC_API_URL_V3}/location/district-by-current-location`, {
      method: 'POST',
      headers: reqHeaders,
      body: JSON.stringify(reqBody)
    })
    const res = await response.json()
    if (res.status === true) {
      return res
    } 
    return res.status
  } catch (error) {
    return error?.response
  }
}
export const getPageType = ()=>{
  const {route} = router.router;

  if(JSON.parse(localStorage.getItem('expandMap'))) return 'SRP_MAP' // if map has been expanded to full page view

  switch(route){
    case '/[slug]/search' : 
      return 'SRP'
    case '/':
      return 'Home'
    default : return 'Not Found'
  }
}
export const searchAnalyticEvent=(details,searchType,inputValue,window,lang,eventName)=>{
  let pageType = getPageType();
  if( pageType==="SRP" && localStorage?.getItem("zonefilterClick")){
  const mapcheck= JSON.parse(localStorage?.getItem("zonefilterClick"))
  if(mapcheck.isMapView){
    pageType="SRP-Map"
  }else{
    pageType="SRP-List"
  }
  }
  const detailsIDCheck = details?.id ? 'by_Property_id' : '' || ""
  const detailsCityIDCheck = details?.cityId ? details?.cityId : details?.city_id || router?.router?.query?.cityId
  const detailsCityCheck = details?.city ? details?.city : ''
  const district_list_check = (details?.district?.join('') || details?.locationType === 'district' ? details?.srpTitle : '')
  const district_count_check = details?.districtId && !Array.isArray(details?.districtId) ? 1 : ''
  const district_id_check = (Array.isArray(details?.districtId) ? details?.districtId?.join('') :  (details?.districtId || ""))
  const eventObj = {
    search_keyword: inputValue || "",
    search_result_clicked: Array.isArray(details?.title) ? details?.title.join(',') : details?.title || "",
    search_result_displayed : '',
    top_location_clicked: (details?.position ? true : false) ,
    recent_search_clicked : (searchType === 'recent search' ? true : false) ,
    use_my_current_loc : searchType === 'location',
    search_type : details?.locationType ? details?.locationType : detailsIDCheck,
    service : details?.propertyFor || details?.service || '',
    popular_district: details?.popularDis ? true : false ,
    nearby_district:  details?.nearBy  ? true : false ,
    start_new_search: inputValue ? true : false,
    city_id: Array.isArray(details?.cityId) ? details?.cityId?.[0] : detailsCityIDCheck,
    city: Array.isArray(details?.city) ? details?.city?.[0] : detailsCityCheck,
    zone:details?.zones?.map(zone=>zone?.name).join(',') || "",
    zone_id:details?.zones?.map(zone=>zone?.id).join(',') || "",
    district_list: (details?.district?.length && details?.district?.length > 0 && Array.isArray(details?.district)) ? details?.district?.join(','): district_list_check, 
    district_count: (details?.district?.length && details?.district?.length > 0) ? details?.district?.length : district_count_check,
    district_id: (details?.districtId?.length && details?.district?.length > 0) ?  details?.districtId?.join(',') : district_id_check,
    permission_status : details?.permission_status || "",
    pill_name : details?.pill_name,
    scrolled_depth:details?.scrolled_depth,
    source: details?.source || '',
    popular_city: details?.popular ?? false
  }
  if(details?.districtId?.length){
    delete eventObj.popular_city
  }
  pushDataLayerEventHandler(window,lang,eventName,pageType,eventObj)
}


export const fetchNearByProperties = async (cities, slugQueries, query, locale, baseUrl) => {
  try {
    const params = getParamsFromQuery({ ...query, ...slugQueries });

    const ciytLocation = cities?.find((city) => city?.cityId == params?.cityId);
    delete params.slug;
  
    const reqBody = {
      ...params,
      page: 0,
      nearbySearch: {
        distance: 5,   //replace 40 with 5
        location: ciytLocation?.center || null,
      },
    };
   
    if(reqBody.sort) { delete reqBody['sort'] }
    
    const response = await axios({
      method: 'post',
      url: `${baseUrl || process.env.NEXT_PUBLIC_API_URL_V3}/properties/filter/nearby`,
      headers:  {
        "Content-Type": "application/json",
        locale,
        module: "post-property",
      },
      data: JSON.stringify(reqBody),
    })
    if (response?.data?.status) {
      return {
        nearbyLocation : reqBody,
        nearByProperties : response?.data?.data
      }
    } 
  } catch (error) {
    console.log(error)
    return {...error.response}
  }
}

export const getNearbyPropertiesByFilterQuries = async (headers, reqBody) => {
  try{
    const response = await fetch(`${process.env.NEXT_PUBLIC_API_URL_V3 || process.env.NEXT_PUBLIC_API_INTERNAL_URL_V3}/properties/filter/nearby`,
    {
      method: "POST",
      headers,
      body: reqBody
    }
  )
  return await response.json()
  } catch (error) {
    console.error("Enable To Fetch Nearby Filter Data", error)
  }
}

export const NextPrevAPI = async (body, lang) => {
  try {
      const res = await fetch(`${process.env.NEXT_PUBLIC_API_URL_V5}/properties/filter`,{
          method: 'Post',
          headers: {
              'Content-Type': 'application/json',
              locale: lang,
          },
          body : JSON.stringify({...body})
      })
      const response = await res.json()
      return response?.data
  } catch (error) {
    console.error("Enable To Fetch Data", error)
  }
}
