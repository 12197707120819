export const pdpReducer = (state, action) => {
  switch (action.type) {
    case 'SET_PDP_MAP_INTERACTION_CLICKED':
      return {
        ...state,
        pdpMapInteractionClicked: action.payload,
      }
    case 'SET_IS_APPROX_LOC':
      return {
        ...state,
        isApproxLoc: action.payload,
      }
    case 'SET_IS_APPROX_OR_EXACT_LOC':
      return {
        ...state,
        isApproxOrExactLoc: action.payload,
      }
    case 'SET_APPROX_OR_EXACT_LOC':
      return {
        ...state,
        approxOrExactLoc: action.payload,
      }
    case 'SET_ON_PDP_SEARCH_FOCUSED':
      return {
        ...state,
        onPDPSearchFocused: action.payload,
      }
    case 'SET_SEE_ON_MAP_CLICKED':
      return {
        ...state,
        seeOnMapClicked: action.payload,
      }
    case 'SET_SELECTED_NEARBY_TYPE_ARRAY':
      return {
        ...state,
        selectedNearbyTypeArray: action.payload,
      }
    case 'SET_SELECTED_NEARBY_TYPE_PLACE':
      return {
        ...state,
        selectedNearbyTypePlace: action.payload,
      }
    case 'SET_REPORT_PROPERTY_PDP':
      return {
        ...state,
        reportProp: action.payload,
      }
    case 'SET_GALLERY_DETAILS_MOBILE_PDP':
      return {
        ...state,
        galleryDetailsMobile: { ...action.payload },
      }
    case 'SET_HIDE_COUNT_PDP':
      return {
        ...state,
        hideCount: action.payload,
      }
    case 'SET_EXPAND_IMAGE_PDP':
      return {
        ...state,
        expandImage: action.payload,
      }
    case 'SET_SHOW_EXPAND_PDP':
      return {
        ...state,
        showExpand: action.payload,
      }
    case 'SET_SHOW_DETAILS_PDP':
      return {
        ...state,
        showDetails: action.payload,
      }
    case 'SET_SHOW_SHARE_PDP':
      return {
        ...state,
        showShare: action.payload,
      }
    case 'SET_SHOW_DOWNLOAD_PDP':
      return {
        ...state,
        showDownload: action.payload,
      }
    case 'SET_FILE_INDEX_PDP':
      return {
        ...state,
        fileIndex: action.payload,
      }
    case 'SET_SWIPER_PDP':
      return {
        ...state,
        swiper: action.payload,
      }
    case 'SET_SELECTED_TAB_PDP':
      return {
        ...state,
        selectedTab: action.payload,
      }
    case 'SET_SELECTED_TAB_MOBILE_PDP':
      return {
        ...state,
        selectedTabMobile: action.payload,
      }
    case 'SET_OPEN_THANK_POPUP_PDP':
      return {
        ...state,
        openThankPopup: action.payload,
      }
    case 'SET_DOWN_PAYMENT_AMOUNT_PDP':
      return {
        ...state,
        downPaymentAmount: action.payload,
      }
    case 'SET_LOAN_AMOUNT_PDP':
      return {
        ...state,
        loanAmount: action.payload,
      }
    case 'SET_ELIGIBLE_BORROW_PDP':
      return {
        ...state,
        eligibleBorrow: action.payload,
      }
    case 'SET_TENURE_PDP':
      return {
        ...state,
        tenure: action.payload,
      }
    case 'SET_AGE_PDP':
      return {
        ...state,
        age: action.payload,
      }
    case 'SET_EXISTING_LOAN_PDP':
      return {
        ...state,
        existingLoan: action.payload,
      }
    case 'SET_EMI_PDP':
      return {
        ...state,
        emi: action.payload,
      }
    case 'SET_USER_DETAILS_PDP':
      return {
        ...state,
        userDetails: { ...action.payload },
      }
    case 'SET_LOAN_SUBMIT_DETAILS_PDP':
      return {
        ...state,
        loanSubmitDetails: { ...action.payload },
      }
    case 'SET_CLIENT_ID_PDP':
      return {
        ...state,
        clientId: action.payload,
      }
    case 'SET_INTEREST_RATE_BASED_ON_TENURE_PDP':
      return {
        ...state,
        interestRateBasedOnTenure: [...action.payload],
      }
    case 'SET_OPEN_HOME_POPUP_PDP':
      return {
        ...state,
        openHomePopup: action.payload,
      }
    case 'SET_MONTHLY_INCOME_PDP':
      return {
        ...state,
        monthlyIncome: action.payload,
      }
    case 'SET_MONTHLY_EMI_PDP':
      return {
        ...state,
        monthlyEMI: action.payload,
      }
    case 'SET_CALL_NOW_HOVERING_PDP':
      return {
        ...state,
        isCallNowHovering: action.payload,
      }
    case 'SET_CALL_HOVERING_PDP':
      return {
        ...state,
        isHovering: action.payload,
      }
    case 'SET_WHATSAPP_URL_PDP':
      return {
        ...state,
        whatsappUrl: action.payload,
      }
    case 'SET_WHATSAPP_POPUP_PDP':
      return {
        ...state,
        whatsappPopUp: action.payload,
      }
    case 'SET_SHOW_POPUP_PDP':
      return {
        ...state,
        showPopup: action.payload,
      }
    case 'SET_DISPLAY_TABS_PDP':
      return {
        ...state,
        displayTabs: action.payload,
      }
    case 'SET_PROPERTY_DETAILS_PDP':
      return {
        ...state,
        propertyDetails: { ...action.payload },
      }
    case 'SET_PROPERTY_DETAILS_V3_PDP':
      return {
        ...state,
        propertyDetailsNew: { ...action.payload },
      }
    case 'SET_GALLERY_DETAILS_PDP':
      return {
        ...state,
        galleryDetails: { ...action.payload },
      }
    case 'SET_INFORMATION_PDP':
      return {
        ...state,
        infoDetails: { ...action.payload },
      }
    case 'SET_HEADER_LINKS_PDP':
      return {
        ...state,
        headerMenuLinks: { ...action.payload },
      }
    case 'SET_FOOTER_LINKS_PDP':
      return {
        ...state,
        homeFooter: { ...action.payload },
      }
    case 'SET_LOCALE_PDP':
      return {
        ...state,
        locale: action.payload,
      }
    case 'SET_PDP_NULL':
      return null
    case 'SET_MONTHLY_PAYMENT_PDP': {
      return {
        ...state,
        monthlyPayment: action.payload,
      }
    }
    case 'UPDATE_ISFEATURE': {
      return {
        ...state,
        isFeature: action.payload,
      }
    }
    case 'SET_AB_TESTING_PDP': {
      return {
        ...state,
        abTestVariant: action.payload,
      }
    }
    case 'SET_WHATSAPP_AB_TESTING_SYR': {
      return {
        ...state,
        whatsappAbTesting: action.payload,
      }
    }
    case 'SET_PHONE_NUMBER': {
      return {
        ...state,
        phNumber: action.payload,
      }
    }
    default:
      return state
  }
}
