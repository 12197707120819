const VALID_AUCTION_MEDIA_TYPES = ['image/jpeg', 'image/png', 'application/pdf']

const PDF_TYPE = 'application/pdf'

const RECENT_CITIES = 'recentCities'

const PROPERTY_TYPES = {
    commercial: 1,
    residential: 2,
    both: 64
}

const LISTING_TYPES = {
    sale: 3,
    rent: 4,
    both: 139
}

const MAX_RECENT_CITIES = 3

const PLOTS_DIGITS_ALLOWED = 4
const UNITS_DIGITS_ALLOWED = 4

const BROKER_ROLE_ID = 11
const DEVELOPER_ROLE_ID = 45
const OWNER_ROLE_ID = 47

const APARTMENT_TYPE = 39
const FLOOR_TYPE = 66

export { 
    VALID_AUCTION_MEDIA_TYPES, PDF_TYPE, RECENT_CITIES, PROPERTY_TYPES, LISTING_TYPES, MAX_RECENT_CITIES, PLOTS_DIGITS_ALLOWED, UNITS_DIGITS_ALLOWED,
    BROKER_ROLE_ID, DEVELOPER_ROLE_ID, OWNER_ROLE_ID, APARTMENT_TYPE, FLOOR_TYPE
}
