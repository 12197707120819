import { fetchApi } from "@/utils/apiCall";

export const saveUnsaveAPI = async (actionName, entityIds, lang, type = 'saved_property', propertyFor = null) =>
  await fetchApi({
    lang,
    baseUrl: 'NEXT_PUBLIC_AUCTION_API_URL_V2',
    subUrl: actionName,
    method: 'POST',
    reqBody: JSON.stringify({
      userId: (Number(window.localStorage.getItem('userId')) || Number(window.localStorage.getItem('user_Id'))) ?? null,
      entityIds,
      type,
      propertyFor,
    }),
  })

export async function getSavedAuctionsProperties(lang, type = 'saved_property') {
  const requestBody = { type , onlyPropertyIds: true }
  const response = await fetchApi({
    lang,
    baseUrl: 'NEXT_PUBLIC_AUCTION_API_URL_V2',
    subUrl: `getAllSavedAuctionsProperties`,
    method: 'POST',
    reqBody: requestBody,
  })
  if(type == 'saved_property') {
    return response?.data?.data?.propertyIdsArray?.map((property) => property.id)
  }
  return []
}
