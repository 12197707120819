import React from 'react'
import Error404 from '@/components/Common/Error404'
import withTranslation from 'next-translate/withTranslation'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    // Define a state variable to track whether is an error or not
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI

    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    // You can use your own error logging service here
    console.log({ error, errorInfo })
  }

  filterProperties = (data) => {
    const { lang } = this.props?.i18n;
    const routeUrl = `/${lang}/sale/search?propertyFor=sale&countryId=1&cityId=${data.cityId}`
    // this.props.router.push(routeUrl, undefined, { shallow: true })
    window.location.href = routeUrl
  }

  render() {
    // Check if the error is thrown
    if (this.state.hasError) {
      // You can render any custom fallback UI
      if(this.props.customView) {
        return this.props.customView
      }
      return (
        <div>
          {/* <h1>Error from ErrorBoundary</h1> */}
          <Error404
            image='/images/application-error.svg'
            heading='ContentErrorPage.ErrorBoundary'
            alt='ContentErrorPage.NOT_FOUND'
            inputId='error-input-view-list'
            filterProperties={this.filterProperties}
          />
        </div>
      )
    }
    return this.props.children
  }
}

export default withTranslation(ErrorBoundary)
