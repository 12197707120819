import combineReducers from 'react-combine-reducers'
import { cdpReducer } from './cdpReducer'
import { udpReducer } from './udpReducer'
import { searchReducer } from './searchReducer'
import { CdpInitialState } from '@/store/App/cdpStore'
import { UdpInitialState } from '@/store/App/udpStore'
import { loginintialState } from '@/store/App/loginStore'
import { loginReducer } from './loginReducer'
import { PdpInitialState } from '@/store/App/pdpStore'
import { pdpReducer } from './pdpReducer'
import { commonReducer } from './commonReducer'
import { CommonInitialState } from '@/store/App/commonStore'
import {searchInitalState} from '@/store/App/searchStore'
import { matchmakerReducer } from './matchmakerReducer'
import { MatchmakerInitalState } from '@/store/App/matchmakerStore'
import { homeSearchInitalState } from '@/store/App/homeSearchStore'
import { homeSearchReducer } from './homeSearchReducer'
import { wasaltBusinessInitalState } from '@/store/App/wasaltBusinessStore'
import { wasaltBusinessReducer } from './wasaltBusinessReducer'

export const [reducerCombined, intialStateCombined] = combineReducers({
  cdpReducer: [cdpReducer, CdpInitialState],
  udpReducer: [udpReducer, UdpInitialState],
  loginReducer: [loginReducer, loginintialState],
  pdpReducer: [pdpReducer, PdpInitialState],
  commonReducer: [commonReducer, CommonInitialState],
  searchReducer : [searchReducer,searchInitalState],
  matchmakerReducer : [matchmakerReducer, MatchmakerInitalState],
  homeSearchReducer : [homeSearchReducer,homeSearchInitalState],
  wasaltBusinessReducer : [wasaltBusinessReducer, wasaltBusinessInitalState]
})
