import { tempInterestRateBasedOnTenure, tempLoanSubmitDetails } from 'constant/appConstant'

// propertyDetails, propertyDetailsNew, galleryDetails, infoDetails, headerMenuLinks, footerLinks, locale

export const UdpInitialState = {
  windowLocation: '',
  unitDetails: {},
  unitBreadcrumbDetails: null,
  propertyDetails: {},
  propertyDetailsNew: {},
  galleryDetails: {},
  infoDetails: {},
  availableUnitList: {},
  udpAvailableUnitList: [],
  refundStatus: {},
  headerMenuLinks: {},
  footerLinks: {},
  locale: 'ar',
  isSeeOnMapClicked: false,
  schedulePropertyTour: false,
  isMobile: null,
  isTabletView: null,
  savedProperties: [],
  showReportPopup: false,
  getPreviousURL: '',
  enPreviousURL: '',
  arPreviousURL: '',
  scrollCount: 0,
  isPaymentSuccess: false,
  mortgageCalculator: {
    monthlyIncome: 0,
    emi: 0,
    existingLoan: 0,
    tenure: 30,
    interestRateBasedOnTenure: tempInterestRateBasedOnTenure,
    loanSubmitDetails: tempLoanSubmitDetails,
  },
  sendScheduleDetails: { pageType: 'UDP', unitCompoundID: '' },
  propertyUnitData: [
    {
      title: '4 Bedrooms Luxurious Villa for Sale',
      status: 'Available',
      tags: ['For Sale'],
      price: '1,500,000 SAR',
      attributes: [
        {
          key: 'noOfBedrooms',
          name: 'Bedrooms',
          value: 2,
          image: 'noofbedrooms.png',
          iconClass: 'bedroom',
        },
        {
          key: 'noOfBathrooms',
          name: 'Bathrooms',
          value: 1,
          image: 'noofbathrooms.png',
          iconClass: 'bathroom',
        },
        {
          key: 'builtUpArea',
          name: 'Built-Up Area',
          value: '200',
          image: 'builtuparea.png',
          unit: 'SQM',
          iconClass: 'builtup-area',
        },
        {
          key: 'carpetArea',
          name: 'Land Area',
          value: '380',
          image: 'carpetarea.png',
          unit: 'SQM',
          iconClass: 'land-area',
        },
        {
          key: 'completionYear',
          name: 'Age',
          value: 0,
          image: 'completionyear.png',
          unit: 'Yr.',
          iconClass: 'age-of-building',
        },
        {
          key: 'noOfLivingrooms',
          name: 'Living Rooms',
          value: null,
          image: 'nooflivingrooms.png',
          iconClass: 'living-room',
        },
        {
          key: 'noOfGuestrooms',
          name: 'Guest Rooms',
          value: null,
          image: 'noofguestrooms.png',
          iconClass: 'guest-room',
        },
        {
          key: 'furnishingType',
          name: 'Furniture',
          value: null,
          image: 'furnishingtype.png',
          iconClass: 'furniture',
        },
      ],
    },
    {
      title: '2 Bedrooms Luxurious Villa for Sale',
      status: 'Available',
      tags: ['For Sale'],
      price: '500,000 SAR',
      attributes: [
        {
          key: 'noOfBedrooms',
          name: 'Bedrooms',
          value: 2,
          image: 'noofbedrooms.png',
          iconClass: 'bedroom',
        },
        {
          key: 'noOfBathrooms',
          name: 'Bathrooms',
          value: 1,
          image: 'noofbathrooms.png',
          iconClass: 'bathroom',
        },
        {
          key: 'builtUpArea',
          name: 'Built-Up Area',
          value: '200',
          image: 'builtuparea.png',
          unit: 'SQM',
          iconClass: 'builtup-area',
        },
        {
          key: 'carpetArea',
          name: 'Land Area',
          value: '380',
          image: 'carpetarea.png',
          unit: 'SQM',
          iconClass: 'land-area',
        },
        {
          key: 'completionYear',
          name: 'Age',
          value: 0,
          image: 'completionyear.png',
          unit: 'Yr.',
          iconClass: 'age-of-building',
        },
        {
          key: 'noOfLivingrooms',
          name: 'Living Rooms',
          value: null,
          image: 'nooflivingrooms.png',
          iconClass: 'living-room',
        },
        {
          key: 'noOfGuestrooms',
          name: 'Guest Rooms',
          value: null,
          image: 'noofguestrooms.png',
          iconClass: 'guest-room',
        },
        {
          key: 'furnishingType',
          name: 'Furniture',
          value: null,
          image: 'furnishingtype.png',
          iconClass: 'furniture',
        },
      ],
    },
  ],
  monthlyEMI: 0,
  displayTabs: [],
  isCallNowHovering: false,
  isHovering: false,
  totalSimilarPropCount: 0,
  totalPropertyCount: 0,
  useMobileState: null,
  fileIndex: {
    current: 1,
    total: 0,
  },
  galleryData: [],
  selectedTab: 'Images',
  showPopup: false,
  nearByProperties: null,
  similarProperties: null,
  whatsappUrl: '',
  whatsappPopUp: false,
  monthlyPayment: 0,
  monthlyIncome: 0,
  openHomePopup: false,
  openThankPopup: false,
  interestRateBasedOnTenure: tempInterestRateBasedOnTenure,
  clientId: null,
  loanSubmitDetails: {
    total: '',
    lead_interval: '',
    lead_percent: '',
    annuity: '',
    eligible_amount: '',
    affordability_status: '',
    calc_type: '',
  },
  userDetails: null,
  emi: null,
  existingLoan: 0,
  tenure: 30,
  eligibleBorrow: '2,000',
  loanAmount: 0,
  downPaymentAmount: 0,
  showDownload: false,
  showShare: false,
  showDetails: false,
  showExpand: false,
  expandImage: false,
  hideCount: false,
  galleryDetailsMobile: {},
  refundStatusMsg: false,
}

// const
