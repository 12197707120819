module.exports={
  "locales": ["en", "ar"],
 "defaultLocale": "ar",
 "localeDetection": false,
 "loadLocaleFrom":  (lang) =>(
           fetch(lang === 'en' ? `${process.env.NEXT_PUBLIC_CMS_STRAPI_BASE_URL}/translations?slug=translation&locale=en` : `${process.env.NEXT_PUBLIC_CMS_STRAPI_BASE_URL}/translations?slug=translation&locale=ar-SA`).then((res) => res.json()) // Use .json() to parse the response
          .then((data) => data.translation_data)) // getting translation_data key
,
 "pages": {
   "*": ["translations"]
 }
}