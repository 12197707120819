export const cdpReducer = (state, action) => {
  switch (action.type) {
    case 'SET_IS_FAVOURITE_COMPOUND':
      return {
        ...state,
        isFavourite: { ...action.payload },
      }
    case 'SET_HIDE_COUNT':
      return {
        ...state,
        hideCount: action.payload,
      }
    case 'SET_EXPAND_IMAGE':
      return {
        ...state,
        expandImage: action.payload,
      }
    case 'SET_SHOW_EXPAND':
      return {
        ...state,
        showExpand: action.payload,
      }
    case 'SET_SHOW_DETAILS':
      return {
        ...state,
        showDetails: action.payload,
      }
    case 'SET_SHOW_SHARE':
      return {
        ...state,
        showShare: action.payload,
      }
    case 'SET_SHOW_DOWNLOAD':
      return {
        ...state,
        showDownload: action.payload,
      }
    case 'SET_SWIPER':
      return {
        ...state,
        swiper: action.payload,
      }
    case 'SET_PROPERTY_DETAILS':
      return {
        ...state,
        propertyDetails: { ...action.payload },
      }
    case 'SET_FAVOURITE_DATA':
      if (!Array.isArray(action.payload)) {
        return {
          ...state,
          favouriteData: [...state.favouriteData, action.payload],
        }
      } else {
        return {
          ...state,
          favouriteData: [...action.payload],
        }
      }

    case 'REMOVE_FAVOURITE_DATA':
      return {
        ...state,
        favouriteData: state.favouriteData.filter((item) => item !== action.payload),
      }
    case 'SET_PROPERTY_DETAILS_V3':
      return {
        ...state,
        propertyDetailsNew: { ...action.payload },
      }
    case 'SET_GALLERY_DETAILS':
      return {
        ...state,
        galleryDetails: { ...action.payload },
      }
    case 'SET_COMPOUND_BREADCRUM_DETAILS':
      return {
        ...state,
        compoundBreadcrumbDetails: { ...action.payload },
      }

    case 'SET_GALLERY_DETAILS_MOBILE':
      return {
        ...state,
        galleryDetailsMobile: { ...action.payload },
      }
    case 'SET_INFORMATION':
      return {
        ...state,
        infoDetails: { ...action.payload },
      }
    case 'SET_HEADER_LINKS':
      return {
        ...state,
        headerMenuLinks: { ...action.payload },
      }
    case 'SET_FOOTER_LINKS':
      return {
        ...state,
        footerLinks: { ...action.payload },
      }
    case 'SET_LOCALE':
      return {
        ...state,
        locale: action.payload,
      }
    case 'SET_MONTHLY_EMI':
      return {
        ...state,
        monthlyEMI: action.payload,
      }
    case 'SET_DISPLAY_TABS':
      return {
        ...state,
        displayTabs: action.payload,
      }
    case 'SET_CALL_NOW_HOVERING':
      return {
        ...state,
        isCallNowHovering: action.payload,
      }
    case 'SET_CALL_HOVERING':
      return {
        ...state,
        isHovering: action.payload,
      }
    case 'SET_TOTAL_SIMILAR_PROP_COUNT':
      return {
        ...state,
        totalSimilarPropCount: action.payload,
      }
    case 'SET_TOTAL_PROPERTY_COUNT':
      return {
        ...state,
        totalPropertyCount: action.payload,
      }
    case 'SET_USE_MOBILE_STATE':
      return {
        ...state,
        useMobileState: action.payload,
      }
    case 'SET_FILE_INDEX':
      return {
        ...state,
        fileIndex: action.payload,
      }
    case 'SET_GALLERY_DATA':
      return {
        ...state,
        galleryData: action.payload,
      }
    case 'SET_SELECTED_TAB':
      return {
        ...state,
        selectedTab: action.payload,
      }
    case 'SET_SELECTED_TAB_MOBILE':
      return {
        ...state,
        selectedTabMobile: action.payload,
      }
    case 'SET_SHOW_POPUP':
      return {
        ...state,
        showPopup: action.payload,
      }
    case 'SET_NEAR_BY_PROPERTIES':
      return {
        ...state,
        nearByProperties: action.payload,
      }
    case 'SET_SIMILAR_PROPERTIES':
      return {
        ...state,
        similarProperties: action.payload,
      }
    case 'SET_WHATSAPP_URL':
      return {
        ...state,
        whatsappUrl: action.payload,
      }
    case 'SET_WHATSAPP_POPUP':
      return {
        ...state,
        whatsappPopUp: action.payload,
      }
    case 'SET_USER_DETAILS':
      return {
        ...state,
        userDetails: action.payload,
      }
    case 'SET_MORTGAGE_CALCULATOR':
      return {
        ...state,
        mortgageCalculator: action.payload,
      }
    case 'SET_COMPOUND_DETAILS':
      return {
        ...state,
        compoundDetails: action.payload.data,
      }
    case 'SET_COMPOUND_STREET_INFO_DETAILS':
      return {
        ...state,
        compoundStreetInfoDetails: action.payload,
      }
    case 'SET_COMPOUND_AMENITIES_DETAILS':
      return {
        ...state,
        compoundAmenitiesDetails: action.payload,
      }
    case 'SET_COMPOUND_GALLERY_DETAILS':
      return {
        ...state,
        compoundGalleryDetails: action.payload,
      }

    case 'SET_PAYLOAD':
      return {
        ...state,
        filterPayload: { ...action.payload },
      }
    case 'SET_ROUTER_PARAMS':
      return {
        ...state,
        routerParams: { ...action.payload },
      }
    case 'SET_SEARCH_RESULT':
      return {
        ...state,
        searchResult: { ...action.payload },
      }
    case 'SET_BACK_FADE':
      return {
        ...state,
        backFade: action.payload,
      }
    case 'SET_ZONE_OPEN':
      return {
        ...state,
        zoneOpen: action.payload,
      }
    case 'SET_LIST_VIEW':
      return {
        ...state,
        listView: action.payload,
      }
    case 'SET_WRAPPER_OPEN':
      return {
        ...state,
        wrapperOpen: action.payload,
      }
    case 'SET_PROPERTY_SEARCH_RESULT':
      return {
        ...state,
        propertySearchResult: action.payload,
      }
    case 'SET_SEE_MORE':
      return {
        ...state,
        seeMore: action.payload,
      }
    case 'SET_ON_CHNAGE_FILTER_COUNT':
      return {
        ...state,
        onChnageFilterCount: action.payload,
      }
    case 'SET_UNIT_SORT':
      return {
        ...state,
        filterData: { ...state.filterData, sort: action.payload },
      }
    case 'SET_PROPERTY_FOR':
      return {
        ...state,
        filterData: { ...state.filterData, propertyFor: action.payload },
      }
    case 'SET_view':
      return {
        ...state,
        filterData: {
          ...state.filterData,
          view: action.payload,
        },
      }
    case 'SET_TYPE':
      return {
        ...state,
        filterData: { ...state.filterData, type: action.payload },
      }
    case 'SET_COMPOUND_ID':
      return {
        ...state,
        filterData: { ...state.filterData, compoundId: action.payload },
      }
    case 'SET_COUNTRY_ID':
      return {
        ...state,
        filterData: { ...state.filterData, countryId: action.payload },
      }
    case 'PROPERTY_TYPES':
      return {
        ...state,
        filterData: { ...state.filterData, property_types: action.payload },
      }
    case 'SET_PROPERTY_TYPES_DATA':
      if (action.payload.length === 0) {
        return {
          ...state,
          filterData: { ...state.filterData, propertyTypeData: action.payload },
        }
      } else {
        return {
          ...state,
          //filterData: { ...state.filterData, propertyTypeData: [...new Set([...state.filterData.propertyTypeData, ...action.payload])] }
          filterData: { ...state.filterData, propertyTypeData: [...action.payload] },
        }
      }
    case 'SET_BATHROOMS_COUNT':
      return {
        ...state,
        filterData: { ...state.filterData, bathroomsCount: [...action.payload] },
      }
    case 'SET_BEDROOMS_COUNT':
      return {
        ...state,
        filterData: { ...state.filterData, bedroomsCount: [...action.payload] },
      }
    case 'SET_FURNISING_TYPE_ID':
      return {
        ...state,
        filterData: { ...state.filterData, furnishingTypeId: action.payload },
      }
    case 'SET_PRICE_RANGE':
      return {
        ...state,
        filterData: { ...state.filterData, priceRange: action.payload ? [...action.payload] : null },
      }
    case 'SET_PROPERTY_SIZE_RANGE':
      return {
        ...state,
        filterData: { ...state.filterData, propertySizeRange: action.payload ? [...action.payload] : null },
      }
    case 'SET_SHOW_AVAILABILITY_ONLY':
      return {
        ...state,
        filterData: { ...state.filterData, showAvailableOnly: action.payload },
      }
    case 'SET_INPUT_VALUE':
      return {
        ...state,
        inputValue: action.payload,
      }
    case 'SEE_MORE_UNIT_POP_UP':
      return {
        ...state,
        seeMoreUnitPopUp: action.payload,
      }
    case 'SEE_MORE_UNIT_DETAIL':
      return {
        ...state,
        seeMoreUnitDetail: action.payload,
      }
    case 'SET_UNIT_FILTER_STICK':
      return {
        ...state,
        unitFilterStick: action.payload,
      }

    case 'SET_SHOW_APPY_BTN':
      return {
        ...state,
        showApplyBtn: action.payload,
      }
    case 'SET_FILES':
      return {
        ...state,
        files: action.payload,
      }
    case 'SET_COMPOUND_UNIT_LIST_DETAILS':
      return {
        ...state,
        compoundUnitList: action.payload,
      }
    case 'SET_COMPOUND_UNIT_LIST_SAVED':
      return {
        ...state,
        compoundUnitListSaved: action.payload,
      }
    case 'SET_CONTEXT_FOR_COOKIES':
      return {
        ...state,
        cookiesContext: action.payload,
      }
    case 'SET_CDP_NULL':
      return null
    default:
      return state
  }
}
