export const wasaltBusinessReducer = (state, action) => {
    switch (action.type) {
        case 'SET_SELECTED_PLAN':
            return {
                ...state,
                selectedPremiumPlan : action.payload
            }
        case 'SET_TRANSACTION_INFO':
            return{
                ...state,
                transactionInfo: action.payload
            }
        case 'SET_PAYMENT_PENDING_INFO':
            return{
                ...state,
                paymentPendingInfo: action.payload
            }
        default:
            return state;
    }
}