export const loginReducer = (state, action) => {
  switch (action.type) {
    case 'SET_CURRENT_SCREEN':
      return {
        ...state,
        currentScreen: action.payload,
      }
    case 'SET_USER_ID':
      return {
        ...state,
        userID: action.payload,
      }
    case 'SET_PHONE_NUMBER':
      return { 
        ...state,
        phoneNumber: action.payload, 
      }
    case 'SET_VISIBLE':
      return {
        ...state,
        visible: action.payload,
      }
    case 'SIMILAR_LOGIN_CLICK':
      return {
        ...state,
        loginInformation: action.payload,
      }
    case 'SET_REF_ID':
      return {
        ...state,
        refID: action.payload,
      }
    default: {
      return state
    }
  }
}
