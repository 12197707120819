export const homeSearchInitalState = {
    propertyFor: 'sale',    // stores "buy" and "rent"
    propertyType: 'residential',    // stores "residential" and "commercial"
    IsSrpMsiteOpen: false,
    propertyForMsite:'sale',
    propertyTypeMsite:'residential',
    isSearchInputVisible:false,
    srpPillData:[],
    recentSearchTrigger:false,
    filterTrigger:false,
    currentLocationTrigger:false,
    isSuggestionOpen:false,
    isPinClicked:false,
    srpSelectedCity: null,  // this is used for intialize the first searchPill in autosearch component
    selectedItem: {         // this object is used for intializing and storing multiple names and ids in searchNewPage based on what user searched 
        cityId: [],
        cityName: [],
        districtId: [],
        districtName: [],
        countryId: [],
        countryName: [],
        zoneId: [],
        zoneName: [],
        zonePropertyCount : 0,
        locationType : '',
        whichSelected:null,
        masterplanBannerLoading: true
    },
    districtSrpData : [], // used to store the district pill data that have been selected on SRP page ,
    routerParams: null, //filter parameter object for getting properties data , needs to be called in autoserch and dispatch in indexPLP 
    enableLocation:false,
    recentSearchValue: null,
    isHomeMsiteOpen:false,
    isHomeStickyInputClicked:false,
    scrollDepth: null,
    selectedPlaceForTitle: '',
    isProject:false,
    showAppHeader: true,
    abTestVariant: false,
    allDistricts:[],
    selectedDistrictsMsite:[],
    allPropertyTypes:[],
    showAllClicked: false
}