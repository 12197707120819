import { listingsPerPageSize } from "@/utils/constant"

export const generateQueryStringFromParams = (params, removeFlag = true, isPageChange = false) => {
  // console.log("TAG: queryString: generateQueryStringFromParams: params", params)
  delete params.slug
  delete params.pageSize
  if (removeFlag) {
    delete params.page
  }
  delete params.includeProperties
  if(!isPageChange){
    delete params.min_size
    delete params.max_size
    delete params.min_price
    delete params.max_price
  }

  const queriesArray = []
  for (const property in params) {
    if (property === 'priceRange') {
      const priceRangeStr = `min_price=${params[property][0].min}&max_price=${params[property][0].max}`
      queriesArray.push(priceRangeStr)
    } else if (property === 'propertySizeRange') {
      const minSize = params[property][0].min
      const maxSize = params[property][0].max
      const propertySizeRangeStr = `min_size=${minSize}&max_size=${maxSize}`
      queriesArray.push(propertySizeRangeStr)
    } else if (property === 'propertyTypeData') {
      let propertyTypeDataStr
      if (Array.isArray(params[property])) {
        propertyTypeDataStr = params[property].join(',')
      } else {
        propertyTypeDataStr = params[property]
      }
      propertyTypeDataStr = `${property}=${propertyTypeDataStr}`
      queriesArray.push(propertyTypeDataStr)
    } else if (property === 'neighborhoodsData') {
      let neighborhoodsDataStr = params[property].join(',')
      neighborhoodsDataStr = `${property}=${neighborhoodsDataStr}`
      queriesArray.push(neighborhoodsDataStr)
    } 
    // else if (property === 'geo_bounding_box') {
    //   let geo_bounding_box = encodeURIComponent( JSON.stringify( params[property] ))
    //   geo_bounding_box = `${property}=${geo_bounding_box}`
    //   queriesArray.push(geo_bounding_box)
    // } 
    else {
      const str = `${property}=${params[property]}`
      if (params[property] != undefined || params[property] != null){
        queriesArray.push(str)
      }
    }
  }

  const combinedQueries = queriesArray.join('&')
  return `?${combinedQueries}`
}

export const getParamsFromQuery = (query) => {
  const filterQueries = { ...query }
  // Remove Slug
  if (query.slug && !query.propertyFor && (query.slug == 'sale' || query.slug == 'rent')) {
    filterQueries.propertyFor = query.slug
    delete filterQueries.slug
  }

  if (query.min_price || query.max_price) {
    filterQueries.priceRange = [
      { min: query.min_price ? query.min_price : 0, max: query.max_price ? query.max_price : 'Any' },
    ]
    delete filterQueries.min_price
    delete filterQueries.max_price
  }

  if (query.min_size || query.max_size) {
    filterQueries.propertySizeRange = [
      { min: query.min_size ? query.min_size : 0, max: query.max_size ? query.max_size : 35000 },
    ]
    delete filterQueries.min_size
    delete filterQueries.max_size
  }
  if (query?.propertyTypeData && typeof query.propertyTypeData === 'string') {
    filterQueries.propertyTypeData = query?.propertyTypeData?.split(',')
  }
  if (query.neighborhoodsData) {
    filterQueries.neighborhoodsData = query.neighborhoodsData?.split(',')
  }
  if (query.districtId && query.districtId === 'null') {
    filterQueries.districtId = null
  }

  if (query.nearbySearch) {
    filterQueries.nearbySearch = query.nearbySearch
  }
  filterQueries.page = filterQueries.page || 0
  filterQueries.pageSize = filterQueries.pageSize || (listingsPerPageSize || 32)
  filterQueries.includeProperties = filterQueries.includeProperties || true
  // filterQueries.geo_bounding_box = filterQueries.geo_bounding_box || null

  return filterQueries
}

export const getParamsFromRefUrl = (refUrl) => {
  const referenceUrlParams = refUrl?.split('?')

  const propertyForSlug = referenceUrlParams[0].split('/')[0]

  const propertyFor = encodeURIComponent(propertyForSlug)

  const referenceQueryString = referenceUrlParams[1]

  const refUrlParams = JSON.parse(
    '{"' + decodeURI(referenceQueryString.replace(/&/g, '","').replace(/=/g, '":"')) + '"}',
  )

  return {
    ...refUrlParams,
    propertyFor,
    page: 0,
    pageSize: listingsPerPageSize || 32,
    includeProperties: true,
  }
}
