
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    
import Axios from 'axios'
import appWithI18n from 'next-translate/appWithI18n'
import useTranslation from 'next-translate/useTranslation'
import { useEffect, useRef, useState } from 'react'
import { AppWrapper } from '../context/AppContext'
import i18nConfig from '../../i18n'
import ErrorBoundary from '../components/Common/ErrorBoundary'
import { initializeProjectStore, initializeStore } from '@/utils/propertyStore'
import { useRouter } from 'next/router'
import { toast } from 'react-toastify'
import { initializeUnitStore } from '@/utils/unitStore'
import 'react-toastify/dist/ReactToastify.css'
import Head from 'next/head'
import '@/styles/global.css'
import '@/styles/base.scss'
import '@/styles/postProperty.scss'
import '@/styles/swiper-custom.scss'
import Script from 'next/script'
import { mixpanelInit } from '@/utils/utils'
import { authCheck } from '@/utils/authCheck'

function loadStyles() {
  if (window.location.pathname.includes('/s/')) {
    import('@/styles/sGlobal.css')
  }
}


Axios.defaults.baseURL = process.env.NEXT_PUBLIC_SERVER_BASE_URL + '/api'
Axios.defaults.withCredentials = true

const MyApp = ({ Component, pageProps }) => {
  const [tabsOpen, setTabsOpen] = useState(0)
  const { t, lang } = useTranslation('translations')
  initializeStore()
  initializeProjectStore()
  initializeUnitStore()
  toast.configure({ limit: 3, rtl: lang == 'ar' ? true : false })
  const { asPath } = useRouter()
  const ref = useRef(null)
  const [isFirstLoadDone, setIsFirstLoadDone] = useState(false)
  const router = useRouter()
  //init mixanel library to capture events

    mixpanelInit()
    
  //call refresh token api when page loads
    if(typeof window !=='undefined'){authCheck()}

  useEffect(() => {
    loadStyles()
  }, [router.pathname])

  useEffect(()=>{
    window.lang = lang
  },[lang])

  useEffect(() => {
    document.addEventListener('click', authCheck);

    // keeps track of number of tabs currently active on browser
    const tabsOpened = localStorage.getItem('tabsOpen')
    if (tabsOpened == null) {
      localStorage.setItem('tabsOpen', 1)
      setTabsOpen(1)
    } else {
      localStorage.setItem('tabsOpen', parseInt(tabsOpened) + parseInt(1))
      setTabsOpen(parseInt(tabsOpened) + parseInt(1))
    }

    return () => {
      document.removeEventListener('click', authCheck);
      // decreases the value of tab count tracker on each tab close
      const newTabCount = localStorage.getItem('tabsOpen')
      if (newTabCount !== null) {
        localStorage.setItem('tabsOpen', parseInt(newTabCount) - 1)
        setTabsOpen(parseInt(tabsOpened) - 1)
      }
    }
  }, [])

  const decodeURLAsPAth = () => {
    try {
      ref.current = asPath

      if (localStorage.getItem('prev_path') == null) {
        localStorage.setItem('prev_path', '[]')
      }
      const old_data = JSON.parse(window.localStorage.getItem('prev_path'))
      if (decodeURI(old_data[old_data.length - 1]) != decodeURI(window.history.state.as)) {
        old_data.push(window.history.state.as)
        if (old_data.length >= 10) {
          old_data.shift()
        }
      }
      window.localStorage.setItem('prev_path', JSON.stringify(old_data))
    } catch (error) {
      console.log(error)
      // URI encoding or decoding wasn't successful. An argument given to either the decodeURI, encodeURI, encodeURIComponent, or decodeURIComponent function was not valid, so that the function was unable encode or decode properly.
      router.push(lang === 'en' ? `en/404` : `/404`)
    }
  }

  useEffect(() => {
    decodeURLAsPAth()
  }, [asPath])
  /**
   * Handle app lang
   * @return void
   */
  useEffect(() => {
    (async () => {
      const direction = lang === 'en' ? 'ltr' : 'rtl'
      setDirection(direction, lang)
    })()
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side')
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles)
    }
  }, [lang])

  /**
   * Set app dircetion and language
   * @return void
   */
  const setDirection = (direction, language) => {
    document.documentElement.dir = direction
    document.documentElement.lang = language
  }

  useEffect(() => {
    setIsFirstLoadDone(true)
    if (!localStorage.getItem('island')) {
      localStorage.setItem('island', true)
    }
  }, [])

  return (
    <>
      <Head>
        <meta name='google' content='notranslate' />
        <link
          href={`${process.env.NEXT_PUBLIC_CDN_BASE_URL}/fonts/iconography.css`}
          fetchpriority='high'
          rel='stylesheet'
          as='style'
        />
      </Head>
      <Script id='gtm' strategy='afterInteractive'>
        {`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID}');
      `}
      </Script>
        <AppWrapper tabsOpen={tabsOpen}>
        <ErrorBoundary>
          <Script src={`${process.env.NEXT_PUBLIC_CDN_BASE_URL}/static/Mixpanel.js`}  strategy='beforeInteractive'/>
          <Component {...pageProps} isUIinitiated={true} t={t} lang={lang} />
        </ErrorBoundary>
        </AppWrapper>
    </>
  )
}

const __Page_Next_Translate__ = appWithI18n(MyApp, i18nConfig)


    export default __appWithI18n(__Page_Next_Translate__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: false,
      
    });
  