import { isUserLoggedIn } from "./utils"

export const PROPERTY_SORT_TYPES = [
  null,
  'attribute.salePrice_asc',
  'attribute.salePrice_desc',
  'publishedAt_desc',
  'size_desc',
  'size_asc',
  'attribute.completionYear_desc',
  'attribute.completionYear_asc',
]

export const SURROUNDING_RADIUS = 5 * 1000 //5 km
export const GOOGLE_PLACE_BASE_URL = 'https://maps.googleapis.com/maps/api/place/nearbysearch/json?location='
export const GOOGLE_API_KEY = 'AIzaSyAwDHDGuHGhCzlwIOyqOrPFURvHDMWLG08'
export const DEFAULT_ERROR_STRING = 'Some thing wrong Error'
export const DEFAULT_ERROR_STATUS = 500
export const API_RESPONSE_SUCCESS = 200
export const API_RESPONSE_CREATE_SUCCESS = 201
export const API_RESPONSE_AUTHENTICATION_ERROR = 401
export const FINDIPPATH = 'https://api.findip.net/'
export const GETIP = 'https://api.bigdatacloud.net/data/client-ip' //'https://api.ipify.org?format=json'

export const getType = (index) => {
  switch (index) {
    case 0:
      return 'shop'
    case 1:
      return 'restaurant'
    case 2:
      return 'mosque'
    case 3:
      return 'hospital'
    case 4:
      return 'school'
  }
}

export const getIndex = (id) => {
  switch (id) {
    case 'shop':
      return 0
    case 'restaurant':
      return 1
    case 'mosque':
      return 2
    case 'hospital':
      return 3
    case 'school':
      return 4
  }
}

export const NumberRegex = /^[0-9\b]+$/
export const TextRegex = /^[a-zA-Z ]{2,30}$/
export const NATIONALITY_ID = '321'
export const FooterURL = '/get-footer-links-by-slug/home-footer'
export const HeaderMenuURL = '/get-navigation-links-by-slug/menu-header'

// autofill data for matchmaker form
export const matchMakerDefaultAutofill = {
  buy: {
    propertyFor: 'sale',
    cityId: '273',
    propertyTypeData: ['villa'],
    paymentType: '1',
    bedroomsCount: '4,5',
    min_price: '0',
    max_price: '10000000',
    pageType: 'Home',
    propertyCategory: 'residential'
  },
  rent: {
    propertyFor: 'rent',
    cityId: '273',
    propertyTypeData: ['apartment'],
    bedroomsCount: '2,3',
    min_price: '0',
    max_price: '500000',
    pageType: 'Home',
    propertyCategory: 'residential'
  },
}
export const brokerDefaultLogo = 'profile-filled-logo'
export const priceRange = { min: 0, max: 10010000, maxPlus: 10000001, maxPlusLabel: '10000000+', step: 10000 }
export const rentPriceRange = { min: 0, max: 505000, maxPlus: 505001, maxPlusLabel: '500000+', step: 1000 }

export const bedroomList = (t) => [t('shareRequirement.Studio'), '1', '2', '3', '4', '5', '6', '7', '8', '9', '9+']

export const maxData = [
  '200,000',
  '225,000',
  '250,000',
  '275,000',
  '300,000',
  '325,000',
  '350,000',
  '400,000',
  '450,000',
  '500,000',
  '550,000',
  '600,000',
  '650,000',
  '700,000',
  '750,000',
  '800,000',
  '900,000',
  '1,000,000',
  '2,000,000',
  '3,000,000',
  '4,000,000',
  '5,000,000',
  '6,000,000',
  '7,000,000',
  '8,000,000',
  '9,000,000',
  '10,000,000',
  '10,000,000+',
]
export const minData = [
  '0',
  '200,000',
  '225,000',
  '250,000',
  '275,000',
  '300,000',
  '325,000',
  '350,000',
  '400,000',
  '450,000',
  '500,000',
  '550,000',
  '600,000',
  '650,000',
  '700,000',
  '750,000',
  '800,000',
  '900,000',
  '1,000,000',
  '2,000,000',
  '3,000,000',
  '4,000,000',
  '5,000,000',
  '6,000,000',
  '7,000,000',
  '8,000,000',
  '9,000,000',
  '10,000,000',
]

export const rentMaxData = [
  '5000',
  '7500',
  '10,000',
  '12,500',
  '15,000',
  '17,500',
  '20,000',
  '25,000',
  '30,000',
  '40,000',
  '50,000',
  '60,000',
  '70,000',
  '80,000',
  '90,000',
  '100,000',
  '150,000',
  '200,000',
  '250,000',
  '300,000',
  '350,000',
  '400,000',
  '450,000',
  '500,000',
  '500,000+',
]
export const rentMinData = [
  '0',
  '5000',
  '7500',
  '10,000',
  '12,500',
  '15,000',
  '17,500',
  '20,000',
  '25,000',
  '30,000',
  '40,000',
  '50,000',
  '60,000',
  '70,000',
  '80,000',
  '90,000',
  '100,000',
  '150,000',
  '200,000',
  '250,000',
  '300,000',
  '350,000',
  '400,000',
  '450,000',
  '500,000',
]

// property type slug - bedroom count will be shown based on specific slugs only
export const showBedroomPropertyTypeSlug = {
  residential: ['apartment', 'villa', 'palace', 'chalet', 'duplex', 'rest-house', 'floor', 'residential-building'],
  commercial: ['labor-camp'],
}

//  key 419 is for sale or buy and key 420 is for rent
export const listingValues = ['419', '420']

//  [SRP,PDP,NRP,LRP] this are the different page types which we have
export const pageName = ['SRP', 'LRP', 'NRP', 'PDP']

// pages in which layout header will be exempted
export const header_exempted_pages = ['BannerLogin', 'Subuser-Invitation']

// value for property type for inside mall
export const propertyValues = ['retail', 'showroom']

// value for budget for matchmaker form
export const price = {
  buy: [
    { type: 'less', min: 0, max: 500000 },
    { type: 'between', min: 500000, max: 1000000 },
    { type: 'between', min: 1000000, max: 2000000 },
    { type: 'more', min: 2000000, max: 10000000 },
  ],
  rent: [
    { type: 'less', min: 0, max: 60000 },
    { type: 'between', min: 60000, max: 80000 },
    { type: 'between', min: 80000, max: 100000 },
    { type: 'more', min: 100000, max: 500000 },
  ],
}

export const toastConst = {
  SUCCESS: 'success',
  INFO: 'info',
  ERROR: 'error',
  WARNING:'warning'
}

export const profileSideMenuTabs= (t,lang)=>({ 
    sidemenu: [
        {
            id: 1,
            icon: "icon-home",
            title:t('common.PROPERTIES'),
            desc: t('common.EXPLORE_PROPERTIES'),
            isLink: false,
            isOpen: false,
            isLoginShow:true,
            eventName:'profile_properties_clicked',
            childTab: [
              {
                childId: 1,
                childIcon: "search-properties",
                childTitle:t('common.SEARCH_RESIDENTIAL'),
                isShowWithoutLogin: true,
                isAddProperty:false,
                childEventName:'profile_searched_residential_clicked',
                redirectURL:`${lang == 'en' ? '/en' : ''}/sale/search?propertyFor=sale&countryId=1&cityId=273&type=residential`
            },
              {
                childId: 2,
                childIcon: "search-commercial",
                childTitle:t('common.SEARCH_COMMERCIAL'),
                isShowWithoutLogin: true,
                isAddProperty:false,
                childEventName:'profile_searched_commercial_clicked',
                redirectURL:`${lang == 'en' ? '/en' : ''}/sale/search?propertyFor=sale&countryId=1&cityId=273&type=commercial`
            },
                {
                    childId: 3,
                    childIcon: "property-sold",
                    childTitle:t('common.CONTACTED_AND_SEEN'),
                    isShowWithoutLogin: true,
                    isAddProperty:false,
                    childEventName:'profile_contacted_seen_clicked',
                    redirectURL:`${process.env.NEXT_PUBLIC_AUCTIONBASE_URL}${lang == 'en' ? '/en' : ''}/user/properties?pType=history&type=contacted_property`
                },
                {
                    childId: 4,
                    childIcon: "heart",
                    childTitle: t('common.SAVED_PROPERTIES'),
                    isShowWithoutLogin: true,
                    isAddProperty:false,
                    childEventName:'profle_saved_itmes_clicked',
                    redirectURL:`${process.env.NEXT_PUBLIC_AUCTIONBASE_URL}${lang == 'en' ? '/en' : ''}/user/properties?pType=saved&type=properties`
                },
                {
                    childId: 5,
                    childIcon: "my-booking",
                    childTitle: t('unitReservation.MY_BOOKINGS'),
                    isShowWithoutLogin: isUserLoggedIn(),
                    isAddProperty:false,
                    childEventName:'profile_my_bookings_clicked',
                    redirectURL:'/user/my-bookings?type=units'
                }
            ]
        },
        {
            id: 2,
            icon: "icon-document",
            title: t('common.REQUEST_YOUR_PROPERTY'),
            desc: isUserLoggedIn() ? t('common.VIEW_OR_EDIT_REQUIREMENT') : t('common.SHARE_YOUR_REQUIREMENT'),
            isLink: true,
            isOpen: false,
            isLoginShow:true,
            eventName:'profile_req_your_prop_clicked',
            redirectURL:`${lang == 'en' ? '/en' : ''}${isUserLoggedIn() ? '/user/myrequirements?source=MyProfile_click':'/post-requirement'}`
        },
        {
            id: 3,
            icon: "icon-auction-outline",
            title: t('common.AUCTIONS'),
            desc:t('common.LIVE_UPCOMING_CLOSED'),
            isOpen: false,
            isLink: false,
            isLoginShow:true,
            eventName:'profile_Auction_clicked',
            redirectURL:`${process.env.NEXT_PUBLIC_AUCTIONBASE_URL}${lang == 'en' ? '/en' : ''}`,
            childTab: [
                {
                    childId: 1,
                    childIcon: "auction-outline",
                    childTitle: t('common.ALL_AUCTIONS'),
                    isShowWithoutLogin: true,
                    isAddProperty:false,
                    childEventName:'profile_all_auction_clicked',
                    redirectURL:`${process.env.NEXT_PUBLIC_AUCTIONBASE_URL}${lang == 'en' ? '/en' : ''}`
                },
                {
                    childId: 2,
                    childIcon: "registered-auctions",
                    childTitle: t('common.REGISTERED_AUCTIONS_REVAMP'),
                    isShowWithoutLogin: isUserLoggedIn(),
                    isAddProperty:false,
                    childEventName:'profile_registered_auction_clicked',
                    redirectURL:`${process.env.NEXT_PUBLIC_AUCTIONBASE_URL}${lang == 'en' ? '/en' : ''}/user/auctions?pType=register&type=live`
                },
                {
                    childId: 3,
                    childIcon: "notification-bell",
                    childTitle: t('common.FOLLOWED_AUCTIONS'),
                    isShowWithoutLogin: isUserLoggedIn(),
                    isAddProperty:false,
                    childEventName:'profile_followed_auction_clicked',
                    redirectURL:`${process.env.NEXT_PUBLIC_AUCTIONBASE_URL}${lang == 'en' ? '/en' : ''}/user/auctions?pType=followed&type=upcoming`
                },
                {
                    childId: 4,
                    childIcon: "ewallet",
                    childTitle: t('common.AUCTIONS_EWALLET'),
                    isShowWithoutLogin: isUserLoggedIn(),
                    isAddProperty:false,
                    childEventName:'profile_ewallet_auction_clicked',
                    redirectURL:`${process.env.NEXT_PUBLIC_AUCTIONBASE_URL}${lang == 'en' ? '/en' : ''}/user/auctions?pType=eWallet`
                }
            ]
        },
        {
          id: 4,
          icon: "icon-manage-properties",
          title: t('common.Manage_Properties'),
          desc: t('common.Manage_Properties_SUB_TXT'),
          isLink: false,
          isOpen: false,
          isLoginShow:isUserLoggedIn(),
          eventName:'profile_manage_your_properties_clicked',
          childTab: [
              {
                  childId: 1,
                  childIcon: "home",
                  childTitle: t('globalNavigation.My_Property'),
                  isShowWithoutLogin: true,
                  isAddProperty:false,
                  childEventName:'profile_my_properties_MP',
                  redirectURL:`${lang == 'en' ? '/en' : ''}/user/myproperty`
              },
              {
                  childId: 2,
                  childIcon: "post-property",
                  childTitle: t('globalNavigation.ADD_PROPERTY'),
                  isShowWithoutLogin: true,
                  isAddProperty:true,
                  childEventName:'profile_add_property_MP',
                  redirectURL:`${lang == 'en' ? '/en' : ''}/post-property`
              },
          ]
      },
    ]

})

export const helpAndSupportOptions = (t,lang)=>([
  {
    title:t('common.HELP_AND_SUPPORT'),
    title_icon:'icon-information',
    icon_right:lang==='en' ? 'icon-chevron-right' :'icon-chevron-left',
    icon_up:'icon-chevron-up',
    icon_down:'icon-chevron-down',
    isContact:true,
    eventName:'profile_helpNsupport_Clicked',
      options: [
        {
          optionTitle: t('PrivacyPolicy.PRIVACY_HEADING'),
          isPDF:false,
          redirectURL: `${lang == 'en' ? '/en' : ''}/s/privacy-policy`
        },
        {
          optionTitle: t('common.TERMS_OF_USE'),
          isPDF:false,
          redirectURL: `${lang == 'en' ? '/en' : ''}/s/terms-of-use`
        },
        {
          optionTitle: t('common.TERMS_OF_ADVERTISING'),
          isPDF:false,
          redirectURL: `${lang == 'en' ? '/en' : ''}/s/terms-of-advertising`
        },
        {
          optionTitle: t('common.AUCTION_TERMS_AND_CONDITION'),
          isPDF:false,
          redirectURL: `${lang == 'en' ? '/en' : ''}/s/terms-n-conditions-for-auction`
        },
        // {
        //   optionTitle: t('common.eWallet_Terms_and_Condition'),
        //   isPDF:false,
        //   redirectURL: `${lang == 'en' ? '/en' : ''}/s/terms-n-conditions-for-ewallet`
        // }
      ]
  },
  {
    title:t('common.Lang'),
    title_icon:'icon-language',
    icon_right:'',
    icon_down:'',
    isContact:false,
    eventName:'profile_language_clicked',
    options: []
  }
])
export const howAuctionWorksAndRechargeEwalletOptions = (t, auctionVideoId = "", _auctionPDF = "") =>  ({
    options: [
      {
        optionTitle: t('common.HOW_AUCTIONS_WORKS'),
        isPDF: true,
        // redirectURL: `https://www.youtube-nocookie.com/embed/${auctionVideoId}?rel=0&enablejsapi=1`
        redirectURL: `https://youtu.be/${auctionVideoId}`
      }
    ]
  })

export const propertyTypeOptions = (t) => [
  {
  id:1,
  title:t('Filter.APARTMENT'),
  value: 'apartment',
  name:'Apartment',
  img: 'apartment',
  isSelected:false,
  whichType:"residential"
},
{
  id:2,
  title: t('Filter.VILLA'),
  value: 'villa-townhouse',
  name:'Villa',
  img: 'villa',
  isSelected:false,
  whichType:"residential"
},
{
  id: 5,
  title: t('Filter.LAND'),
  value: 'residential-land',
  name:'Land',
  img: 'land',
  isSelected:false,
  whichType:"residential"
},
{
  id: 10,
  title: t('Filter.FLOOR'),
  value: 'floor',
  name:'Floor',
  img: 'floors',
  isSelected:false,
  whichType:"residential"
},
{
  id: 4,
  title: t('Filter.BUILDING'),
  value: 'building',
  name:'Building',
  img: 'building',
  isSelected:false,
  whichType:"residential"
},
{
  id: 7,
  title: t('Filter.RENT_HOUSE'),
  value: 'rest-house',
  name:'Rest house',
  img: 'rest-house',
  isSelected:false,
  whichType:"residential"
},
{
  id: 6,
  title: t('Filter.FARM'),
  value: 'farm-house',
  name:'Farm',
  img: 'farm',
  isSelected:false,
  whichType:"residential"
},
{
  id: 3,
  title: t('Filter.PALACE'),
  value: 'palace',
  name:'Palace',
  img: 'palace',
  isSelected:false,
  whichType:"residential"
},
{
  id: 9,
  title:t('Filter.DUPLEX'),
  value: 'duplex',
  name:'Duplex',
  img: 'duplex',
  isSelected:false,
  whichType:"residential"
},
{
  id: 8,
  title: t('Filter.CHALET'),
  value: 'chalet',
  name:'Chalet',
  img: 'chalet',
  isSelected:false,
  whichType:"residential"
},
]
export const liveGifen = `${process.env.NEXT_PUBLIC_CDN_BASE_URL}/manuals/banners/Live%20Tag_en%20animation.gif`
export const liveGifar = `${process.env.NEXT_PUBLIC_CDN_BASE_URL}/manuals/banners/Live%20Tag_arabic%20animation.gif`
  
export const bankDataConst = {
  RIZE_BANK_SLUG: 'rize-bank',
  MORTGAGE_BANK_SLUG : "dar-al-tamleek"
}
export const commercialAndResidentialPropertyTypeOptions = (t) =>
  ({
    residential:[
      {
      id:1,
      title:t('Filter.APARTMENT'),
      value: 'apartment',
      name:'Apartment',
      img: 'apartment',
      isSelected:false,
      whichType:"residential",
      count:t('Filter.APARTMENT'),
      selected:false,
    },
    {
      id:2,
      title: t('Filter.VILLA'),
      value: 'villa-townhouse',
      name:'Villa',
      img: 'villa',
      isSelected:false,
      whichType:"residential",
      count:t('Filter.VILLA'),
      selected:false,
    },
    {
      id: 5,
      title: t('Filter.LAND'),
      value: 'residential-land',
      name:'Land',
      img: 'land',
      isSelected:false,
      whichType:"residential",
      count:t('Filter.LAND'),
      selected:false,
    },
    {
      id: 10,
      title: t('Filter.FLOOR'),
      value: 'floor',
      name:'Floor',
      img: 'floors',
      isSelected:false,
      whichType:"residential",
      count:t('Filter.FLOOR'),
      selected:false,
    },
    {
      id: 4,
      title: t('Filter.BUILDING'),
      value: 'building',
      name:'Building',
      img: 'building',
      isSelected:false,
      whichType:"residential",
      count:t('Filter.BUILDING'),
      selected:false,
    },
    {
      id: 7,
      title: t('Filter.RENT_HOUSE'),
      value: 'rest-house',
      name:'Rest house',
      img: 'rest-house',
      isSelected:false,
      whichType:"residential",
      count:t('Filter.RENT_HOUSE'),
      selected:false,
    },
    {
      id: 6,
      title: t('Filter.FARM'),
      value: 'farm-house',
      name:'Farm',
      img: 'farm',
      isSelected:false,
      whichType:"residential",
      count:t('Filter.FARM'),
      selected:false,
    },
    {
      id: 3,
      title: t('Filter.PALACE'),
      value: 'palace',
      name:'Palace',
      img: 'palace',
      isSelected:false,
      whichType:"residential",
      count:t('Filter.PALACE'),
      selected:false,
    },
    {
      id: 9,
      title:t('Filter.DUPLEX'),
      value: 'duplex',
      name:'Duplex',
      img: 'duplex',
      isSelected:false,
      whichType:"residential",
      count:t('Filter.DUPLEX'),
      selected:false,
    },
    {
      id: 8,
      title: t('Filter.CHALET'),
      value: 'chalet',
      name:'Chalet',
      img: 'chalet',
      isSelected:false,
      whichType:"residential",
      count:t('Filter.CHALET'),
      selected:false,
    },
    ],
    commercial:[{
        id:11,
        title: t('Filter.LAND'),
        value: 'land',
        name:'Land',
        img: 'land',
        isSelected:false,
        whichType:"commercial",
        count:t('Filter.LAND'),
        selected:false,
      },
      {
        id: 13,
        title: t('Filter.SHOWROOM'),
        value: 'showroom-1',
        name:'Showroom',
        img: 'retail-outlet',
        isSelected:false,
        whichType:"commercial",
        count:t('Filter.SHOWROOM'),
        selected:false,
      },
      {
        id: 14,
        title: t('Filter.BUILDING'),
        value: 'building-1',
        name:'Building',
        img: 'building',
        isSelected:false,
        whichType:"commercial",
        count:t('Filter.BUILDING'),
        selected:false,
      },
      {
        id: 12,
        title: t('Filter.OFFICE_SPACE'),
        value: 'office-space',
        name:'Office space',
        img: 'office-space',
        isSelected:false,
        whichType:"commercial",
        count:t('Filter.OFFICE_SPACE'),
        selected:false,
      },
      {
        id: 15,
        title: t('Filter.Warehouse'),
        value: 'warehouse',
        name:'Warehouse',
        img: 'warehouse',
        isSelected:false,
        whichType:"commercial",
        count: t('Filter.Warehouse'),
        selected:false,
      },
      {
        id: 16,
        title: t('Filter.Compound'),
        value: 'compound',
        name:'Compound',
        img: 'building',
        isSelected:false,
        whichType:"commercial",
        count: t('Filter.Compound'),
        selected:false,
      },
      {
        id: 17,
        title: t('Filter.Workshop'),
        value: 'workshop-1',
        name:'Workshop',
        img: 'multipurpose-hall',
        isSelected:false,
        whichType:"commercial",
        count: t('Filter.Workshop'),
        selected:false,
      },
      ],
    residentialProject: [
      {
        id: 1,
        count: t('Filter.APARTMENT'),
        value: 'apartment',
        img: 'apartment',
        selected: false,
      },
      {
        id: 2,
        count: t('Filter.VILLA'),
        value: 'villa-townhouse',
        img: 'villa',
        selected: false,
      },
      {
        id: 9,
        count: t('Filter.DUPLEX'),
        value: 'duplex',
        img: 'duplex',
        selected: false,
      },
    ]
  })

//ums payment link flow
export const HYPERPAY_STATUS = {
  FAILED: 'failed',
  PENDING: 'pending'
}
export const PURCHASE_TYPE = {
  UPGRADED: 'upgraded',
  RENEWED: 'renewed'
}

export const WIDTH = {
  MOBILE: 767
}

export const EN = 'en'

export const PACKAGES = {
  MATCHMAKER: 'matchmaker'
}

export const SAR = 'sar'

export const listingsPerPageSize = 32;

export const PAYMENT_CANCELLED = 'cancelled';
export const PAYMENT_PENDING = 'pending';
export const PAYMENT_INITIATED = 'initiated';
export const PAYMENT_SUCCESS = 'success';
export const SHOW_SELECT_PLAN = 'showSelectPlan'
export const SHOW_LOADER = 'showLoader'
export const PAYMENT_FAILED = 'failed'
export const PAYMENT_ALREADY_PENDING = 'alreadyPending'