export const udpReducer = (state, action) => {
  switch (action.type) {
    case 'SET_GALLERY_DETAILS_MOBILE_UDP':
      return {
        ...state,
        galleryDetailsMobile: { ...action.payload },
      }
    case 'SET_HIDE_COUNT_UDP':
      return {
        ...state,
        hideCount: action.payload,
      }
    case 'SET_EXPAND_IMAGE_UDP':
      return {
        ...state,
        expandImage: action.payload,
      }
    case 'SET_SHOW_EXPAND_UDP':
      return {
        ...state,
        showExpand: action.payload,
      }
    case 'SET_SHOW_DETAILS_UDP':
      return {
        ...state,
        showDetails: action.payload,
      }
    case 'SET_SHOW_SHARE_UDP':
      return {
        ...state,
        showShare: action.payload,
      }
    case 'SET_SHOW_DOWNLOAD_UDP':
      return {
        ...state,
        showDownload: action.payload,
      }
    case 'SET_OPEN_THANK_POPUP_UDP':
      return {
        ...state,
        openThankPopup: action.payload,
      }
    case 'SET_DOWN_PAYMENT_AMOUNT_UDP':
      return {
        ...state,
        downPaymentAmount: action.payload,
      }
    case 'SET_LOAN_AMOUNT_UDP':
      return {
        ...state,
        loanAmount: action.payload,
      }
    case 'SET_ELIGIBLE_BORROW_UDP':
      return {
        ...state,
        eligibleBorrow: action.payload,
      }
    case 'SET_TENURE_UDP':
      return {
        ...state,
        tenure: action.payload,
      }
    case 'SET_AGE_UDP':
      return {
        ...state,
        age: action.payload,
      }
    case 'SET_EXISTING_LOAN_UDP':
      return {
        ...state,
        existingLoan: action.payload,
      }
    case 'SET_EMI_UDP':
      return {
        ...state,
        emi: action.payload,
      }
    case 'SET_USER_DETAILS_UDP':
      return {
        ...state,
        userDetails: { ...action.payload },
      }
    case 'SET_LOAN_SUBMIT_DETAILS_UDP':
      return {
        ...state,
        loanSubmitDetails: { ...action.payload },
      }
    case 'SET_CLIENT_ID_UDP':
      return {
        ...state,
        clientId: action.payload,
      }
    case 'SET_INTEREST_RATE_BASED_ON_TENURE_UDP':
      return {
        ...state,
        interestRateBasedOnTenure: [...action.payload],
      }
    case 'SET_OPEN_HOME_POPUP_UDP':
      return {
        ...state,
        openHomePopup: action.payload,
      }
    case 'SET_MONTHLY_INCOME_UDP':
      return {
        ...state,
        monthlyIncome: action.payload,
      }
    case 'SET_PROPERTY_DETAILS_UDP':
      return {
        ...state,
        propertyDetails: { ...action.payload },
      }
    case 'SET_WINDOW_LOCATION':
      return {
        ...state,
        windowLocation: action.payload,
      }
    case 'SET_PROPERTY_DETAILS_V3_UDP':
      return {
        ...state,
        propertyDetailsNew: { ...action.payload },
      }
    case 'SET_GALLERY_DETAILS_UDP':
      return {
        ...state,
        galleryDetails: { ...action.payload },
      }
    case 'SET_INFORMATION_UDP':
      return {
        ...state,
        infoDetails: { ...action.payload },
      }
    case 'SET_HEADER_LINKS_UDP':
      return {
        ...state,
        headerMenuLinks: { ...action.payload },
      }
    case 'SET_FOOTER_LINKS_UDP':
      return {
        ...state,
        footerLinks: { ...action.payload },
      }
    case 'SET_LOCALE_UDP':
      return {
        ...state,
        locale: action.payload,
      }
    case 'SET_MONTHLY_EMI_UDP':
      return {
        ...state,
        monthlyEMI: action.payload,
      }
    case 'SET_DISPLAY_TABS_UDP':
      return {
        ...state,
        displayTabs: action.payload,
      }
    case 'SET_CALL_NOW_HOVERING_UDP':
      return {
        ...state,
        isCallNowHovering: action.payload,
      }
    case 'SET_CALL_HOVERING_UDP':
      return {
        ...state,
        isHovering: action.payload,
      }
    case 'SET_TOTAL_SIMILAR_PROP_COUNT_UDP':
      return {
        ...state,
        totalSimilarPropCount: action.payload,
      }
    case 'SET_TOTAL_PROPERTY_COUNT_UDP':
      return {
        ...state,
        totalPropertyCount: action.payload,
      }
    case 'SET_USE_MOBILE_STATE_UDP':
      return {
        ...state,
        useMobileState: action.payload,
      }
    case 'SET_FILE_INDEX_UDP':
      return {
        ...state,
        fileIndex: action.payload,
      }
    case 'SET_GALLERY_DATA_UDP':
      return {
        ...state,
        galleryData: action.payload,
      }
    case 'SET_SELECTED_TAB_UDP':
      return {
        ...state,
        selectedTab: action.payload,
      }
    case 'SET_SHOW_POPUP_UDP':
      return {
        ...state,
        showPopup: action.payload,
      }
    case 'SET_NEAR_BY_PROPERTIES_UDP':
      return {
        ...state,
        nearByProperties: action.payload,
      }
    case 'SET_SIMILAR_PROPERTIES_UDP':
      return {
        ...state,
        similarProperties: action.payload,
      }
    case 'SET_WHATSAPP_URL_UDP':
      return {
        ...state,
        whatsappUrl: action.payload,
      }
    case 'SET_WHATSAPP_POPUP_UDP':
      return {
        ...state,
        whatsappPopUp: action.payload,
      }
    case 'SET_MORTGAGE_CALCULATOR_UDP':
      return {
        ...state,
        mortgageCalculator: action.payload,
      }
    case 'SET_UNIT_DETAILS':
      return {
        ...state,
        unitDetails: action.payload.data,
      }
    case 'SET_IS_PAYMENT_SUCCESS':
      return {
        ...state,
        isPaymentSuccess: action.payload,
      }
    case 'SET_REFUND_STATUS':
      return {
        ...state,
        refundStatus: action.payload.data,
      }
    case 'SET_AVAILABLE_UNIT':
      return {
        ...state,
        availableUnitList: action.payload.data,
      }
    case 'SET_COMPOUND_BREADCRUM_DETAILS_UDP':
      return {
        ...state,
        unitBreadcrumbDetails: { ...action.payload },
      }
    case 'SET_MONTHLY_PAYMENT_UDP': {
      return {
        ...state,
        monthlyPayment: action.payload,
      }
    }
    case 'SET_AVAILABLE_UNIT_LIST_DETAILS_UDP':
      return {
        ...state,
        udpAvailableUnitList: action.payload,
      }
    case 'SET_SCROLL_COUNT_UDP':
      return {
        ...state,
        scrollCount: action.payload,
      }
    case 'REFUND_STATUS_MSG':
      return {
        ...state,
        refundStatusMsg: action.payload,
      }
    case 'SET_UDP_NULL':
      return null
    default:
      return state
  }
}
