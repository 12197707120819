import { getAssetsBaseURL } from '@/utils/utils'
import axios from 'axios'

export const getPropertyDetails = async (reqHeaders, id) => {
  try {
    const queryString = ''
    const response = await axios.get(`${process.env.NEXT_PUBLIC_API_URL}/properties/${id}${queryString}`, {
      headers: reqHeaders,
    })
    return response.data
  } catch ($err) {
    return { status: 'error', data: {} }
  }
}

export const getCompoundDetails = async (reqHeaders, id) => {
  try {
    const queryString = `projectId=${id}&view=DETAIL_PAGE_MOBILE`
    const response = await axios.get(
      `${
        process.env.NEXT_PUBLIC_HUDDLE_API_INTERNAL_URL_V1 || process.env.NEXT_PUBLIC_HUDDLE_API_URL_V1
      }project-detail?${queryString}`,
      {
        headers: reqHeaders,
      },
    )
    return response.data
  } catch ($err) {
    return { status: 'error' }
  }
}

export const getPropertyDetailsV3 = async (reqHeaders, id) => {
  try {
    const queryString = ''
    const response = await axios.get(`${process.env.NEXT_PUBLIC_API_URL_V3}/properties/${id}${queryString}`, {
      headers: reqHeaders,
    })
    return response.data
  } catch ($err) {
    return { status: 'error', data: {} }
  }
}

// https://api-qa3.wasalt.com/huddle/v1/compound/35/gallery?view=web
export const getGalleryDetails = async (reqHeaders, id, isMobile) => {
  try {
    const queryString = `view=${isMobile ? 'mobile' : 'web'}`
    const response = await axios.get(
      `${
        process.env.NEXT_PUBLIC_HUDDLE_API_INTERNAL_URL_V1 || process.env.NEXT_PUBLIC_HUDDLE_API_URL_V1
      }compound/${id}/gallery?${queryString}`,
      {
        headers: reqHeaders,
      },
    )
    return response.data
  } catch ($err) {
    return { status: 'error', data: {} }
  }
}

export const getInformation = async (reqHeaders, id) => {
  try {
    const response = await axios.get(`${process.env.NEXT_PUBLIC_API_URL_V3}/property-amenities/${id}`, {
      headers: reqHeaders,
    })
    return response.data
  } catch ($err) {
    return { status: 'error', data: {} }
  }
}

export const getUnitDetails = async (reqHeaders, id) => {
  try {
    const response = await axios.post(
      `${process.env.NEXT_PUBLIC_API_URL_MISC}/compound/units`,
      {
        propertyId: id,
      },
      {
        headers: reqHeaders,
      },
    )
    return response?.data
  } catch ($err) {
    return { status: 'error', data: {} }
  }
}
export const getCompoundStreetInfoCall = async (reqHeaders, id) => {
  try {
    const response = await axios.get(
      `${
        process.env.NEXT_PUBLIC_HUDDLE_API_INTERNAL_URL_V1 || process.env.NEXT_PUBLIC_HUDDLE_API_URL_V1
      }/compound/${id}/street-info`,
      {
        headers: reqHeaders,
      },
    )
    return response.data
  } catch ($err) {
    return { status: 'error', data: {} }
  }
}

export const getCompoundAmenitiesCall = async (reqHeaders, id) => {
  try {
    const response = await axios.get(
      `${
        process.env.NEXT_PUBLIC_HUDDLE_API_INTERNAL_URL_V1 || process.env.NEXT_PUBLIC_HUDDLE_API_URL_V1
      }compound/${id}/amenities`,
      {
        headers: reqHeaders,
      },
    )
    return response.data
  } catch ($err) {
    return { status: 'error', data: {} }
  }
}
export const getCompoundGalleryCall = async (reqHeaders, id) => {
  try {
    const response = await axios.get(`${process.env.NEXT_PUBLIC_HUDDLE_API_URL_V1}gallery?compoundId=${id}`, {
      headers: reqHeaders,
    })
    return response.data
  } catch ($err) {
    return { status: 'error', data: {} }
  }
}
export const getCompoundUnitList = async (reqHeaders, payload) => {
  try {
    const response = await axios.post(
      `${process.env.NEXT_PUBLIC_API_INTERNAL_URL_V1 || process.env.NEXT_PUBLIC_API_URL_V1}compound/units`,
      payload,
      {
        headers: reqHeaders,
      },
    )
    return response?.data
  } catch ($err) {
    return { status: 'error', data: $err }
  }
}
export const getCompoundUnitListForScroll = async (reqHeaders, payload) => {
  try {
    const response = await fetch(
      `/api/generic?apiUrl=${
        process.env.NEXT_PUBLIC_API_INTERNAL_URL_V1 || process.env.NEXT_PUBLIC_API_URL_V1
      }compound/units`,
      {
        method: 'POST',
        headers: reqHeaders,
        body: JSON.stringify(payload),
      },
    )
    const data = await response.json()
    return data?.data
  } catch ($err) {
    return { status: 'error', data: $err }
  }
}

export const getUnitListByFilter = async (reqHeaders, payload) => {
  try {
    const res = await fetch(`${process.env.NEXT_PUBLIC_API_URL_V1}compound/units`, {
      method: 'POST',
      headers: reqHeaders,
      body: JSON.stringify(payload),
    })
    const response = await res.json()
    return response?.data
  } catch ($err) {
    return { status: 'error', data: $err }
  }
}

export const getCompoundBreadcrumbDetails = async (reqHeaders, countryId, cityId, pFor) => {
  try {
    const res = await fetch(
      `${process.env.NEXT_PUBLIC_API_INTERNAL_URL_V1 || process.env.NEXT_PUBLIC_API_URL_V1}seo-info-by-reference-url`,
      {
        method: 'POST',
        headers: reqHeaders,
        body: JSON.stringify({
          referenceUrl: `${pFor}/search?type=residential&countryId=${countryId}&cityId=${cityId}`,
        }),
      },
    )
    const response = await res.json()
    return response?.data
  } catch ($err) {
    return { status: 'error', data: $err }
  }
}

export const getUnitBreadcrumbDetails = async (reqHeader, country_id, city_id, p_for) => {
  try {
    const res = await fetch(
      `${process.env.NEXT_PUBLIC_API_INTERNAL_URL_V1 || process.env.NEXT_PUBLIC_API_URL_V1}seo-info-by-reference-url`,
      {
        method: 'POST',
        headers: reqHeader,
        body: JSON.stringify({
          referenceUrl: `${p_for}/search?type=residential&countryId=${country_id}&cityId=${city_id}`,
        }),
      },
    )
    const response = await res.json()
    return response?.data
  } catch ($err) {
    return { status: 'error', data: $err }
  }
}

export const getGalleryKeyData = (img, cID) => ({
    type: 'image',
    filename: img.content,
    url: `${getAssetsBaseURL()}/compound/${cID}/images/${encodeURI(img.content)}/q=75`,
  })

export const getCompoundDetailsClint = async (reqHeaders, id) => {
  try {
    const queryString = `projectId=${id}&view=DETAIL_PAGE_MOBILE`;
    const apiUrl =
      process.env.NEXT_PUBLIC_HUDDLE_API_URL_V1;

    const response = await fetch(`${apiUrl}project-detail?${queryString}`, {
      method: 'GET',
      headers: reqHeaders,
    });
    if (response.ok) {
      const responseData = await response.json();
      return responseData?.data;
    } else {
      return { status: 'error', statusCode: response.status };
    }
  } catch (error) {
    return { status: 'error', message: error.message };
  }
};
export const getSimilarPropertiesCompound = async (reqHeaders, body) => {
  const url = `${process.env.NEXT_PUBLIC_API_URL_V3}/properties/similar-properties`
  const reqBody = JSON.stringify({ ...body })
  const { locale, Cookie } = reqHeaders
  const res = await axios({
    method: 'POST',
    url,
    headers: {
      'Content-Type': 'application/json',
      Cookie: Cookie || 'i18next=en',
      locale,
    },
    data: reqBody,
  })
  return res?.data?.data
}
