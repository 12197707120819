import { tempInterestRateBasedOnTenure } from "@/constant/appConstant";

export const PdpInitialState = {
  propertyDetails: {},
  propertyDetailsNew: {},
  galleryDetails: {},
  infoDetails: {},
  headerMenuLinks: {},
  homeFooter: {},
  locale: 'ar',
  displayTabs: [],
  selectedTab: 'Images',
  selectedTabMobile: '',
  showPopup: false,
  reportProp: false,
  whatsappUrl: '',
  whatsappPopUp: false,
  isCallNowHovering: false,
  isHovering: false,
  monthlyEMI: 0,
  monthlyIncome: 0,
  openHomePopup: false,
  openThankPopup: false,
  isFeature: null,
  interestRateBasedOnTenure:tempInterestRateBasedOnTenure,
  clientId: null,
  loanSubmitDetails: {
    total: '',
    lead_interval: '',
    lead_percent: '',
    annuity: '',
    eligible_amount: '',
    affordability_status: '',
    calc_type: '',
  },
  userDetails: {},
  emi: null,
  existingLoan: 0,
  tenure: 30,
  eligibleBorrow: '2,000',
  loanAmount: 0,
  downPaymentAmount: 0,
  swiper: null,
  fileIndex: {
    current: 1,
    total: 0,
  },
  showDownload: false,
  showShare: false,
  showDetails: false,
  showExpand: false,
  expandImage: false,
  hideCount: false,
  galleryDetailsMobile: {},
  monthlyPayment: 0,
  selectedNearbyTypePlace: null,
  selectedNearbyTypeArray: [false, false, false, false, false],
  seeOnMapClicked: false,
  onPDPSearchFocused: false,
  approxOrExactLoc: null,
  isApproxOrExactLoc: false,
  isApproxLoc: false,
  pdpMapInteractionClicked: false,
  abTestVariant: false,
  whatsappAbTesting: false,
  phNumber:''
}
