import Router from 'next/router';
import { removeCookies } from '@/utils/utils';
import { RECENT_CITIES } from 'constants';

export const fetchApi = async ({
  subUrl = "",
  method = "GET",
  lang,
  reqBody = null,
  header = {},
  baseUrl,
}) => {
  try {
  if (baseUrl) {
    const subUrlCheck = `subUrl=${subUrl}`
    const res = await fetch(
      `/api/common?baseUrl=${baseUrl}&${encodeURI(subUrlCheck)}`,
      {
        method,
        headers: {
          "Content-Type": "application/json",
          locale: lang,
          authorization: window.localStorage.getItem("authToken") ?? "",
          Cookie: `i18next=${lang}`,
          ...header,
        },
        body: reqBody ? JSON.stringify(reqBody) : null,
      }
    );
    const response = await res.json();
    if (res.status == 401) {
      localStorage.removeItem('authToken')
      localStorage.removeItem('userId')
      localStorage.removeItem('user_Id')
      localStorage.removeItem('stepData')
      localStorage.removeItem('currentStep')
      localStorage.removeItem('image_data')
      localStorage.removeItem('firstName')
      localStorage.removeItem('phoneNumber')
      localStorage.removeItem('countryCode')
      localStorage.removeItem(RECENT_CITIES)
      localStorage.removeItem('isExpiredPropertyAlertClosed')
      localStorage.removeItem('paymentStatusRequest')
      localStorage.removeItem('refreshToken')
      localStorage.removeItem('authStoredDate')
      
      removeCookies()
  
      // Router.push('/user/login')
      Router.replace(`/${lang}`)  // after login redirect to home
      setTimeout(() => {
        Router.reload()
      }, 2000)
    }
    return response;
  }
  }
  catch (error)
  {
    console.error("Error occurred in fetchApi : " ,error)
  }
};

export const fetchApi1 = async ({
  subUrl = "",
  method = "GET",
  lang,
  reqBody = null,
  header = {},
  baseUrl,
}) => {
  if (baseUrl) {
    const subUrlCheck = `subUrl=${subUrl}`
    const res = await fetch(
      `/api/common?baseUrl=${baseUrl}&${encodeURI(subUrlCheck)}`,
      {
        method,
        headers: {
          "Content-Type": "application/json",
          locale: lang,
          authorization: window.localStorage.getItem("authToken") ?? "",
          Cookie: `i18next=${lang}`,
          ...header,
        },
        body: reqBody ? JSON.stringify(reqBody) : null,
      }
    );
    const response = await res.json();
    if (res.status == 401) {
      localStorage.removeItem('authToken')
      localStorage.removeItem('userId')
      localStorage.removeItem('user_Id')
      localStorage.removeItem('refreshToken')
      localStorage.removeItem('authStoredDate')
      removeCookies()
      // Router.push('/user/login')
      Router.replace(`/${lang}`)  // after login redirect to home
    }
    return response;
  }
};