import { observerService } from "./observer"
import { isUserLoggedIn } from "./utils";

var defaultStore = {
    seen: [],
    contacted: [],
    favourites: []
}
export const unitStoreKey = 'unitStore';

export const initializeStore = () => {
    let localStorageData = getDataFromLocalStorage()
    if (!localStorageData || !localStorageData.seen || !localStorageData.contacted || !localStorageData.favourites) {
        setDataToLocalStorage(defaultStore)
    }
}

export const initializeUnitStore = () => {
    let localStorageData = getDataFromLocalStorage(unitStoreKey)
    if (!localStorageData || !localStorageData.seen || !localStorageData.contacted || !localStorageData.favourites) {
        setDataToLocalStorage(defaultStore, unitStoreKey)
    }
}

export const getDataFromLocalStorage = (key = 'unitStore') => {
    if (typeof window !== "undefined") {
        return JSON.parse(window.localStorage.getItem(key) || `{}`)
    }
}

const setDataToLocalStorage = (data, key = 'unitStore') => {
    if (typeof window !== "undefined")
        return window.localStorage.setItem(key, JSON.stringify(data))
}

export const setUnitSeen = (data, key = 'unitStore') => {
    let store = getDataFromLocalStorage(key)
    if (store.seen.indexOf(data.id) == -1) {
        store.seen.push(data.id)
    }
    setDataToLocalStorage(store, key)
    // passing event to observer
    observerService.handleEvent({ type: 'seen', cardType: key == 'unitStore' ? 'property' : 'project', data: store });
}

export const setUnitContacted = (data, key = 'unitStore') => {
    let store = getDataFromLocalStorage(key)
    if (store.contacted.indexOf(data.id) == -1) {
        store.contacted.push(data.id)
    }
    setDataToLocalStorage(store, key)
    // passing event to observer
    observerService.handleEvent({ type: 'contacted', cardType: key == 'unitStore' ? 'property' : 'project', data: store });
}

export const setUnitFavourite = (data, key = 'unitStore') => {
    let store = getDataFromLocalStorage(key)
    let index = store.favourites.indexOf(data)
    let favourites = store.favourites
    if (isUserLoggedIn()) {
        if (index === -1) {
          favourites.push(data);
        } else {
          favourites.splice(index, 1);
        }
      } else {
        if (index === -1 && favourites.length < 10) {
          favourites.push(data);
        } else if (favourites.includes(data)) {
          favourites.splice(index, 1);
        } else {
          favourites.push(data);
          favourites.splice(0, 1);
        }
      }
    setDataToLocalStorage(store, key)
    // passing event to observer
    observerService.handleEvent({ type: 'favourite', cardType: key == 'unitStore' ? 'property' : 'project', data: store });
}


export const setUnitFavouriteFromBackend = (data, key = 'unitStore') => {
    let store = getDataFromLocalStorage(key)
    let index = store.favourites.indexOf(data);
    if (index == -1) {
        store.favourites.push(data)
    }
    setDataToLocalStorage(store, key)
    // passing event to observer
    observerService.handleEvent({ type: 'favourite', cardType: key == 'unitStore' ? 'property' : 'project', data: store });
}

export const clearUnitFavourite = (key = 'unitStore') => {
    let store = getDataFromLocalStorage(key)
    store.favourites = []
    setDataToLocalStorage(store, key)
}