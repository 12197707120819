import { getAssetsBaseURL, getDeviceType, getYoutubeId } from '@/utils/utils'
import axios from 'axios'
import uniqueIdGenerator from '@/utils/generateUniqueId'

export const getPropertyDetails = async (reqHeaders, id) => {
  const queryString = ''
  const response = await axios.get(
    `${process.env.NEXT_PUBLIC_API_INTERNAL_URL || process.env.NEXT_PUBLIC_API_URL}/properties/${id}${queryString}`,
    {
      headers: reqHeaders,
    },
  )
  return response.data
}

export const getLocationLinks = async (propertyId, locale) => {
  try {
    locale = `${locale}${locale === 'ar' ? '-SA' : ''}`
    const resp = await fetch(process.env.NEXT_PUBLIC_CMS_STRAPI_BASE_URL + `/internal-location-links-pdp?id=${propertyId}&locale=${locale}`)
    const response_data = await resp.json()
    return response_data || null
  } catch (error) {
    return error?.response
  }
}

export const getNotificationStatus = async (reqHeaders, id) => {
  try {
    const response = await axios.get(`${process.env.NEXT_PUBLIC_GATEWAY_URL}refund-status/${id}`, {
      headers: reqHeaders,
    })
    return await response.data
  } catch ($err) {
    return { status: 'error', data: {} }
  }
}

export const getPropertyDetailsV3 = async (reqHeaders, id) => {
  const queryString = ''
  const response = await axios.get(
    `${
      process.env.NEXT_PUBLIC_API_INTERNAL_URL_V3 || process.env.NEXT_PUBLIC_API_URL_V3
    }/properties/${id}${queryString}`,
    {
      headers: reqHeaders,
    },
  )
  return response.data
}

export const getGalleryDetails = async (reqHeaders, id, isMobile) => {
  const queryString = `${isMobile ? '?view=DETAIL_PAGE_MOBILE' : ''}`
  const response = await axios.get(
    `${
      process.env.NEXT_PUBLIC_API_INTERNAL_URL_V3 || process.env.NEXT_PUBLIC_API_URL_V3
    }/property-gallery/${id}${queryString}`,
    {
      headers: reqHeaders,
    },
  )
  return response.data
}

export const getInformation = async (reqHeaders, id) => {
  const response = await axios.get(
    `${process.env.NEXT_PUBLIC_API_INTERNAL_URL_V3 || process.env.NEXT_PUBLIC_API_URL_V3}/property-amenities/${id}`,
    {
      headers: reqHeaders,
    }, 
  )
  return response.data
}
export const getSimilarProperties = async (reqHeaders, body) => {
  const url = `${process.env.NEXT_PUBLIC_API_URL_V3}/properties/similar-properties`
  const reqBody = JSON.stringify({ ...body })
  const { locale, Cookie } = reqHeaders
  const res = await axios({
    method: 'POST',
    url,
    headers: {
      'Content-Type': 'application/json',
      Cookie: Cookie || 'i18next=en',
      locale,
    },
    data: reqBody,
  })
  return res?.data?.data
}

export const getNearByProperties = async (reqHeaders, body) => {
  const url = `${process.env.NEXT_PUBLIC_API_INTERNAL_URL_V3 || process.env.NEXT_PUBLIC_API_URL_V3}/properties/nearby`
  const reqBody = JSON.stringify({ ...body })
  const { locale, Cookie } = reqHeaders
  const res = await axios({
    method: 'POST',
    url,
    headers: {
      'Content-Type': 'application/json',
      Cookie: Cookie || 'i18next=en',
      locale,
    },
    data: reqBody,
  })
  return res?.data?.data
}

export const getRemoteConfig = async (reqHeaders, entityIds) => {
  try {
    const api_v1 = process.env.NEXT_PUBLIC_API_INTERNAL_URL_V1 || process.env.NEXT_PUBLIC_API_URL_V1
    const response = await axios.get(`${api_v1}remote-config?entityGroup=${entityIds}`, {
      headers: reqHeaders,
    })
    const res = await response?.data
    const data = res?.data
    if (!res?.status) {
      return []
    }
    if (res?.status === true) {
      return data
    }
    throw res?.status
  } catch (error) {
    return error?.response
  }
}

export const isFeaturedPlusProject = (item) => item?.packageData?.subsPkg === 'premium-project' || false

// isFeaturedProject to be initialized with item?.packageData?.subsPkg === 'feature-project' currently as per requirements its false
export const isFeaturedProject = () => false

export const fetchTrendingProjects = async (reqHeaders, body) => {
  try {
    const res = await fetch(`${process.env.NEXT_PUBLIC_API_URL_V1}compound/trending-projects`, {
      method: 'POST',
      headers: reqHeaders,
      body: JSON.stringify(body),
    })
    return res.json()
  } catch (error) {
    return { status: 'error', data: error }
  }
}

export const propMainType = (id) => {
  switch (id) {
    case 95:
      return 'property'
    case 96:
      return 'project'
    case 97:
      return 'masterplan'
    default:
      return null
  }
}

// this function is for new events parameter
export const propTypeAndCategoryEvents = (data, countryId = 1, subPropertyType = 'residential') => {
  let propertyTypes = []
  if (data?.propertyTypes) {
    Object.entries(data?.propertyTypes).map((property) => {
      if (property.name) {
        propertyTypes.push(property.name)
      }
    })
  } else {
    data?.projectInfo?.unitTypes?.map((propertyData) => {
      if (propertyData.name) {
        propertyTypes.push(propertyData.name)
      }
    })
  }
  if (propertyTypes.length > 0) {
    propertyTypes = propertyTypes.toString()
  }

  const id = data?.propIdentityTypeId
  const propertySubType_check = (id == 95 ? data?.propertyInfo?.propertySubType : propertyTypes)
  const countryCheck = (countryId == 1 ? 'domestic' : 'international')
  const propertyMainType_check= (id == 95 ? data?.propertyInfo?.propertyMainType : subPropertyType)
  return {
    property_main_type: propMainType(id) || null,
    property_type: id ? propertySubType_check : null,
    property_category: countryId ? countryCheck : null,
    property_sub_category: id ? propertyMainType_check : null,
  }
}
export const getGalleryKeyDataPDP = (img, pID) => ({
    type: 'image',
    filename: img.content,
    fromCdn: true,
    url: `${getAssetsBaseURL()}/properties/${pID}/images/${encodeURI(img.content)}/quality=60,format=auto${
      process.env.NEXT_PUBLIC_IS_PRODUCTION == 'true' ? ',width=250' : ''
    }`,
  })
export const getVideoGalleryKeyData = (vid) => ({
    type: 'video',
    url: getYoutubeId(vid.content) && getYoutubeId(vid.content)[2],
    shareUrl: vid.content,
  })
export const getBrochureGalleryKeyData = (pdf, pID, thumb) => ({
    type: 'pdf',
    filename: pdf.content,
    url: `${process.env.NEXT_PUBLIC_BASE_FILE_URL}/properties/${pID}/brochure/${pdf.content}`,
    thumb,
  })
export const getFloorPlansGalleryKeyData = (pdf, pID, thumb) => ({
    type: 'pdf',
    filename: pdf.content,
    url: `${process.env.NEXT_PUBLIC_BASE_FILE_URL}/properties/${pID}/floor-plans/${pdf.content}`,
    thumb,
  })
export const getLink360GalleryKeyData = (item, links360) => {
  const { type, iconClass, thumbnail } = links360
  return {
    type,
    iconClass,
    thumbnail,
    url: `${item.content}`,
  }
}
export const getPropertyDetailsClint = async (reqHeaders, id) => {
  try {
    const queryString = '';
    const apiUrl =
      process.env.NEXT_PUBLIC_API_URL_V3;

    const response = await fetch(`${apiUrl}/properties/${id}${queryString}`, {
      method: 'GET',
      headers: reqHeaders,
    });
    if (response.ok) {
      const responseData = await response.json();
      return responseData?.data;
    } else {
      return { status: 'error', statusCode: response.status };
    }
  } catch (error) {
    return { status: 'error', message: error.message };
  }
};

export const getGalleryDetailsClint = async (reqHeaders, id, isMobile) => {
  try {
    const queryString = isMobile ? '?view=DETAIL_PAGE_MOBILE' : '';
    const apiUrl =
      process.env.NEXT_PUBLIC_API_URL_V3;

    const response = await fetch(
      `${apiUrl}/property-gallery/${id}${queryString}`,
      {
        method: 'GET',
        headers: reqHeaders,
      }
    );
    if (response.ok) {
      const responseData = await response.json();
      return responseData?.data;
    } else {
      return { status: 'error', statusCode: response.status };
    }
  } catch (error) {
    return { status: 'error', message: error.message };
  }
};

export const getPricePredictionData = async (id,lang) =>{
  try {
    const reqHeaders = {
      'Content-Type': 'application/json',
      Cookie: 'i18next=undefined',
      locale: lang
      }
      const apiUrl =
      process.env.NEXT_PUBLIC_API_URL_V1;
  
    const response = await fetch(
      `${apiUrl}/property-price-growth-prediction/${id}`,
      {
        method: 'GET',
        headers: reqHeaders,
      }
    );
    if (response.ok) {
      const responseData = await response.json();
      return responseData?.data;
    } else {
      return { status: 'error', statusCode: response.status };
    }
  } catch (error) {
    return { status: 'error', message: error.message };
  }

}

export const getPropertyData = async(reqHeaders, id) =>{
  const response = await fetch(
    `${process.env.NEXT_PUBLIC_API_INTERNAL_URL_V3 || process.env.NEXT_PUBLIC_API_URL_V3}/properties-data/${id}?api1=gallery&api2=property&api3=amenities`,
    {
      headers: reqHeaders,
    },
  )
  const res = await response.json()
  return res.data
}

export const getPropertyInsightPackage = async(lang) =>{
  try {
    const reqHeaders = {
      'Content-Type': 'application/json',
      Cookie: 'i18next=undefined',
      locale: lang,
      Authorization: window.localStorage.getItem("authToken") ?? "",
      }
      const response = await fetch(`${process.env.NEXT_PUBLIC_CRMWASALT_GATEWAY_URL_V2}/subscription-manager/package/property-insight`, {
        method: 'GET',
        headers: reqHeaders
      });
      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }
      const data = await response.json();
    return data?.data;
  } catch (error) {
    return null;
  }
  
}

export const initiatePropertyInsightCheckout = async(data,lang) => {
  try {
    const response = await fetch(`${process.env.NEXT_PUBLIC_GATEWAY_URL_V3}/subscription-manager/initiate-checkout`, {
      method: 'POST',
      headers: {
        locale: lang,
        Authorization: window.localStorage.getItem("authToken") ?? "",
        module: 'wasalt-front-app',
        'current-app': 'wasalt-front-app',
        'Content-Type': 'application/json',
        'x-request-id': uniqueIdGenerator.getId(),
        Cookie: `i18next=${lang}`,
      },
      body: JSON.stringify(data) 
    });

    
    if (!response.ok) {
      throw new Error(`Error: ${response.status}`);
    }
    const result = await response.json();
    return result?.data;
  } catch (error) {
    return null;
  }
}

export const getUserInsightsData = async(lang) =>{
  try {
    const reqHeaders = {
      'Content-Type': 'application/json',
      Cookie: 'i18next=undefined',
      locale: lang,
      Authorization: window.localStorage.getItem("authToken") ?? "",
      }
      const response = await fetch(`${process.env.NEXT_PUBLIC_GATEWAY_URL_V1}property-insight/get-user-views`, {
        method: 'GET',
        headers: reqHeaders
      });
      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }
      const data = await response.json();
    return data?.data;
  } catch (error) {
    return null;
  }
}

export const setUserInsights = async(data,lang) => {
  try {
    const response = await fetch(`${process.env.NEXT_PUBLIC_GATEWAY_URL_V1}property-insight/save-property-views`, {
      method: 'POST',
      headers: {
        locale: lang,
        Authorization: window.localStorage.getItem("authToken") ?? "",
        'Content-Type': 'application/json',
        Cookie: `i18next=${lang}`,
      },
      body: JSON.stringify(data) 
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status}`);
    }
    const result = await response.json();
    return result;
  } catch (error) {
    return null;
  }
}

export const getInsightsPaymentStatus = async(data,lang) => {
  try {
    const response = await fetch(`${process.env.NEXT_PUBLIC_GATEWAY_URL_V2}subscription-manager/payment-status`, {
      method: 'POST',
      headers: {
        locale: lang,
        Authorization: window.localStorage.getItem("authToken") ?? "",
        'Content-Type': 'application/json',
        Cookie: `i18next=${lang}`,
        source: getDeviceType()
      },
      body: JSON.stringify(data) 
    });
    if (!response.ok) {
      throw new Error(`Error: ${response.status}`);
    }
    const result = await response.json();
    return result?.data;
  } catch (error) {
    return null;
  }
}

export const getOrderPendingStatus = async (lang, userId, subscriptionPlan) =>{
  try {
    const res = await fetch(`${process.env.NEXT_PUBLIC_CRMWASALT_GATEWAY_URL}/v1/paymentLink/get-order-pending-status?userId=${userId}&subscriptionSlug=${subscriptionPlan}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        locale: lang,
        Authorization: window.localStorage.getItem("authToken") ?? "",
        source: getDeviceType(),
        module:'property-insight'
      },
    })
    const response = await res.json()
    return response?.data;
  } catch (error) {
    console.error("API failure", error)
  }
}

export const paymentPendingStatusInfo = async (lang,body) => {
  const url = `${process.env.NEXT_PUBLIC_CRMWASALT_GATEWAY_URL}/v1/subscription-manager/current-payment-status`
  try {
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        locale: lang,
        Authorization: localStorage.getItem("authToken"),
        source: getDeviceType(),
        module:'wasalt-business'
    },
      body: JSON.stringify({ ...body }),
    })
    const response = await res.json()
    return response?.data;
  } catch (error) {
    console.error("API failure", error)
  }
}