export const CommonInitialState = {
  pType: '',
  type: '',
  remoteConfigProperties: {},
  remoteConfigCompound: {},
  remoteConfigInfoCompound: {},
  remoteConfigInfoProperty: {},
  remoteConfigInfoPropertyNew: {},
  isOpenAppHeaderEnabled: false,
  propertyId: '',
  isOutsideClick: false,
  isDropDownDataEmpty: false,
  removedPillLocationTypeIsCountry: false,
  removedPillLocationTypeIsCity: false,
  removedPillLocationTypeIsDistrict: false,
  sluqQueries: null,
  featureAgentDetails:  [],

}
